import React, { useState, useEffect } from 'react';
import '../styles/Workbench.css';
import EditComponent from './Workbench/EditComponent';
import GobbleComponent from './Workbench/GobbleComponent';
import { useWorkbenchContext } from './WorkbenchContext';
import { useAuth } from '../auth/AuthContext';
import avatar from '../assets/avatar-final2.svg';
import logoutpic from '../assets/logout.svg';
import myplan from '../assets/myplan.svg';
import { Link, useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Summarizer from './Workbench/Summarizer';
import ChatBotTool from './Workbench/ChatBotTool';
import TitleTool from './Workbench/TitleTool';

const Workbenchcomp = () => {
  const { currentUser, logout } = useAuth();
  const { selectedComponent } = useWorkbenchContext();
  const navigate = useNavigate();

  const [dropdownVisible, setDropdownVisible] = useState(false);

  // Function to toggle the dropdown visibility
  const toggleDropdown = () => {
      setDropdownVisible(!dropdownVisible);
  };

  return (
    <div className='workbench-container'>
      <div className='workbench-component-title-container'>
        <h1>
        {selectedComponent === 'rag-workbench' ? 'RAG Playground' :
         selectedComponent === 'summarizer' ? 'Summarizer' :
         selectedComponent === 'chatbot-tool' ? 'Chat Bot' :
         selectedComponent === 'title-tool' ? 'YouTube Video Finder' : 'misc' }
        </h1>
        <section className={`logins2 ${dropdownVisible ? 'active' : ''}`}>
        <img
            className='account-avatar2'
            src={avatar}
            alt='avatar'
            onClick={toggleDropdown} // Toggle the dropdown on image click
        ></img>
        <section className={`logins-dropdown2 ${dropdownVisible ? 'visible' : ''}`}>
                <button className='dropdown-element2' onClick={() => { navigate('/pricing') }} >
                    <label>My Plan</label> 
                    <img src={myplan} alt='logout'></img>
                </button>
                <button className='dropdown-element2' onClick={logout} >
                    <label>Log out</label> 
                    <img src={logoutpic} alt='logout'></img>
                </button>
        </section>
        </section>
      </div>
      <div className='workbench-component-display'>
        {selectedComponent === 'rag-workbench' ? <GobbleComponent /> 
        :selectedComponent === 'summarizer' ? <Summarizer /> :
        selectedComponent === 'chatbot-tool' ? <ChatBotTool /> :
        selectedComponent === 'title-tool' ? <TitleTool /> : '' }
      </div>
    </div>
  );
};

export default Workbenchcomp;
