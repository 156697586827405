import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import TermsAndConditions from '../components/TermsOfService';

const TosRoute = () => {
  return (
    <>
      <Helmet>
        <title>ragobble - Terms of Service</title>
        <meta name="description" content="Read the Terms of Service of ragobble to understand the rules and guidelines for using our services." />
        <link rel="canonical" href="https://ragobble.com/tos" />
      </Helmet>
      <div>
        <main>
          <TermsAndConditions />
        </main>
      </div>
    </>
  );
};

export default TosRoute;
