import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../../auth/AuthContext';
import '../../styles/Gobbler.css';
import modernArrow from '../../assets/modern-arrow.svg';
import upArrow from '../../assets/right-arrow.svg';
import { Tooltip, Modal, TextField, Button, Box } from '@mui/material';
import pencil from '../../assets/pencil.svg';
import trash from '../../assets/trash.svg';
import FileDeletion from '../FileDeletion';
import Kbcreation from '../Kbcreation';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import GetStarted from '../GetStarted';
import NewUserKB from '../NewUserKB';
import FolderDeletion from '../FolderDeletion';
import DuplicateKb from '../DuplicateKb';
import kbIcon from '../../assets/brain2.svg'
import Logo from '../../assets/ragobble.svg';
import MaxKb from '../MaxKb';
import sparkles from '../../assets/sparkles2.svg';
import aboutIcon from '../../assets/about2.svg';
import uploadSuccess from '../../assets/uploadLoader.svg';
import uploadLoader from '../../assets/ovalLoader.svg';
import { useWebSocketStatus } from '../WebSocketStatusContext.js';
import PopupImageComponent from '../WbPopup.jsx';
import avatar from '../../assets/avatar-final.svg';
import FileAdded from '../FileAdded.jsx';
import YtAdded from '../YtAddition.jsx';
import WpAdded from '../WebpageAdded.jsx';
import KbcUnavailable from '../KbcUnavailable.jsx';
import { set } from 'lodash';
import bulkImport from '../../assets/bulkImport.svg';
import ytUploadEx from '../../assets/ytUploadEx.png';
import linkUploadEx from '../../assets/linkUploadEx.png';
import channelUploadEx from '../../assets/channelUploadEx.png';
import pdfPic from '../../assets/pdfpic.svg';
import txtPic from '../../assets/txtpic.svg';
import docxPic from '../../assets/docxpic.svg';




const GobbleComponent = () => {
  const { currentUser, authToken } = useAuth();
  const [folders, setFolders] = useState([]);
  const [uploadStatus, setUploadStatus] = useState('');
  const [selectedFolder, setSelectedFolder] = useState(''); // State to track the selected folder
  const [knowledgeBaseContent, setKnowledgeBaseContent] = useState('');
  const [question, setQuestion] = useState('');
  const [qaPairs, setQaPairs] = useState([]); // Array to store question-answer pairs
  const [youtubeUrls, setYoutubeUrls] = useState(new Map()); // State to hold the YouTube URLs per folder
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [inputUrl, setInputUrl] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showCreateKbModal, setShowCreateKbModal] = useState(false);
  const [newKbName, setNewKbName] = useState('');
  const [ws, setWs] = useState(null);
  const [showKbMessage, setShowKbMessage] = useState(false);
  const [showFileDeleteMessage, setShowFileDeleteMessage] = useState(false);
  const [folderCount, setFolderCount] = useState(null);
  const [showKbDeleteMessage, setShowKbDeleteMessage] = useState(false);
  const [showDuplicateKbMessage, setShowDuplicateKbMessage] = useState(false);
  const [showMaxKb, setShowMaxKb] = useState(false);
  const { setStatus } = useWebSocketStatus();
  const [showFileAdded, setShowFileAdded] = useState(false);
  const [showVideoAdded, setShowVideoAdded] = useState(false);
  const [showWpAdded, setShowWpAdded] = useState(false);
  const [kbcUnavailable, setKbcUnavailable] = useState(false);
  const containerRef = useRef(null);
  const [batchUploadLink, setBatchUploadLink] = useState('');
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const [showModalSelectionModal, setShowModalSelectionModal] = useState(false);

  useEffect(() => {
    function connectWebSocket() {
      const token = localStorage.getItem('authToken');
      const websocket = new WebSocket(`wss://ragobble.com/ws?token=${token}`);
  
      websocket.onopen = () => {
        setStatus('Connected');
        setWs(websocket);
      };
  
      websocket.onmessage = (event) => {
        setQaPairs(prevQaPairs => {
          const lastIdx = prevQaPairs.length - 1;
          if (lastIdx >= 0) {
            return [
              ...prevQaPairs.slice(0, lastIdx),
              {
                ...prevQaPairs[lastIdx],
                answer: prevQaPairs[lastIdx].answer + event.data
              }
            ];
          }
          return prevQaPairs;
        });
      };
  
      websocket.onerror = (error) => {
        console.error('WebSocket Error:', error);
      };
  
      websocket.onclose = () => {
        setStatus('Reconnecting');
        setTimeout(() => {
          connectWebSocket();
        }, 3000);  // Reconnect after 3 seconds
      };
  
      return () => {
        websocket.close();
        setStatus('Disconnected');
      };
    }
  
    const cleanup = connectWebSocket();
  
    // Cleanup function to close the WebSocket when the component unmounts
    return cleanup;
  }, []);
  

  let currentUserEmail;

  if (currentUser) {
    currentUserEmail = currentUser.email;
  };

  const closeKbMessage = () => {
    setShowKbMessage(false);
  }

  const closeFileDeleteMsg = () => {
    setShowFileDeleteMessage(false);
  };

  const s3FilenameToUrl = (s3Filename) => {
    // Remove the `.txt` extension
    let strippedFilename = s3Filename.replace(/\.txt$/, '');
  
    // Extract the encoded URL portion
    let segments = strippedFilename.split('/');
    let encodedUrl = segments.pop();
  
    // Decode the URL
    let url = encodedUrl
      .replace(/-hyphen-/g, '-')
      .replace(/-dot-/g, '.')
      .replace(/-underscore-/g, '_')
      .replace(/-colon-/g, ':')
      .replace(/-slash-/g, '/');
    return url;
  };
  
  const deleteFile = async (folderName, fileName) => {
    try {
      const response = await axios.post('https://ragobble.com/delete-file', {
        userEmail: currentUserEmail,
        folderName,
        fileName
      }, 
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });
      setShowFileDeleteMessage(true);
      setTimeout(() => closeFileDeleteMsg(), 2500);  // Set to true after 5 seconds
      fetchFolders();  // Re-fetch folders to update the UI
      fetchAndStoreKnowledgeBase(selectedFolder);
    } catch (error) {
      console.error('Error deleting file:', error);
      alert('Failed to delete file');
    }
  };  

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);  // Update the state to the selected file
  };
  useEffect(() => {
    if (selectedFolder) {
      fetchAndStoreKnowledgeBase(selectedFolder);
    }
  }, [selectedFolder, currentUserEmail]);

  const fetchAndStoreKnowledgeBase = async (folderName) => {
    try {
        const response = await axios.post('https://ragobble.com/get-knowledge-base-url', 
        {
            folderName
        }, 
        {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        });

        if (!response.data.signedUrl) throw new Error('Signed URL not found in response');
        
        const fileResponse = await fetch(response.data.signedUrl);
        if (!fileResponse.ok) throw new Error('Failed to fetch file content');
        
        const fileContent = await fileResponse.text();
        setKnowledgeBaseContent(fileContent); // Update the state
    } catch (error) {
        console.error("Error fetching or storing file:", error);
        setKnowledgeBaseContent(''); // Optionally reset content or set an error message
    }
};


  const handleFolderSelect = (e, folderName) => {
    e.stopPropagation();  // Prevent event from bubbling up when inner buttons are clicked
    setSelectedFolder(folderName);  // Update the selected folder
};
  
  useEffect(() => {
    fetchFolders();
  }, [currentUser, currentUserEmail]);

  const fetchFolders = async () => {
    if (currentUser) {
      try {
        const response = await axios.post('https://ragobble.com/list-knowledge-bases', 
        {
            userEmail: currentUser.email
        }, 
        {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        });

        const formattedFolders = response.data.folders.map((folder) => ({
          ...folder,
          expanded: false // Initially, all folders are not expanded
        }));
  
        setFolderCount(response.data.folders.length);
        setFolders(formattedFolders);
  
        // Initialize youtubeUrls map
        const newYoutubeUrls = new Map();
        formattedFolders.forEach((folder) => newYoutubeUrls.set(folder.name, ''));
        setYoutubeUrls(newYoutubeUrls);
  
        if (formattedFolders.length > 0) {
          setSelectedFolder(formattedFolders[0].name);
        } else {
          setSelectedFolder('');
        }
      } catch (error) {
        console.error('Failed to fetch folders:', error);
        setSelectedFolder('');
      }
    }
};


  const toggleFolder = (folderName) => {
    const updatedFolders = folders.map(folder => {
        if (folder.name === folderName) {
            return { ...folder, expanded: !folder.expanded };
        } else {
            return { ...folder, expanded: false };
        }
    });
    setFolders(updatedFolders);
};
  
  const handleKbNameChange = (event) => {
    let input = event.target.value.replace(/\s/g, '');
    if (input.length > 25) {
        input = input.slice(0, 25);
    }
    setNewKbName(input);
  };


  const createKnowledgeBase = async (event) => {
    event.preventDefault(); // Prevent form from submitting traditionally

    if (newKbName) {
      try {
        const response = await axios.post(
          'https://ragobble.com/create-knowledge-base',
          { userEmail: currentUserEmail, kbName: newKbName }, // Payload
          {
            headers: {
              Authorization: `Bearer ${authToken}`, // Separate Authorization header
              'Content-Type': 'application/json'
            }
          }
        );

        if (response.status === 200) {
          fetchFolders();
          setShowCreateKbModal(false);
          setNewKbName('');
          setShowKbMessage(true);
          setTimeout(() => setShowKbMessage(false), 5000);
        }
      } catch (error) {
        console.error('Failed to create knowledge base:', error);
        if (error.response && error.response.status === 400) {
          setShowDuplicateKbMessage(true);
          setShowCreateKbModal(false);
          setNewKbName('');
          setTimeout(() => setShowDuplicateKbMessage(false), 5000);
        }

        if (error.response && error.response.status === 403) {
          setShowMaxKb(true);
          setNewKbName('');
          setShowCreateKbModal(false);
          setTimeout(() => setShowMaxKb(false), 5000);
        }

      }
    }
  };
  

  const deleteKnowledgeBase = async (folderName) => {
    try {
      await axios.post('https://ragobble.com/delete-knowledge-base', 
      { userEmail: currentUserEmail, kbName: folderName },
      {
        headers: {
          Authorization: `Bearer ${authToken}`, // Separate Authorization header
          'Content-Type': 'application/json'
        }
      }
    );
    
      setShowKbDeleteMessage(true);
      setTimeout(() => { setShowKbDeleteMessage(false) }, 5000);
      fetchFolders();
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to delete knowledge base:', error);
    }
  };

  const batchUploadVideos = async (folderName) => {
    setUploadStatus('Uploading'); // Set status to uploading
    try {
      await axios.post('https://ragobble.com/batch-upload-youtube-transcriptions', 
      { youtubeChannelUrl: batchUploadLink, userEmail: currentUserEmail, folderName: folderName },
      {
        headers: {
          Authorization: `Bearer ${authToken}`, // Separate Authorization header
          'Content-Type': 'application/json'
        }
      }
    );
    fetchFolders();
    setUploadStatus('Uploaded');
    setShowVideoAdded(true);
    fetchAndStoreKnowledgeBase(selectedFolder);
    setTimeout(() => {
      setUploadStatus('');        
      setShowVideoAdded(false);
    }, 2000);
    } catch (error) {
      console.error('Failed to delete knowledge base:', error);
    }
  };
  

  const toggleKbEdit = () => {
    setIsEditing(!isEditing);
  };

  const selectFiles = () => {
    return new Promise((resolve) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.multiple = true; // Allow multiple file selection
      input.onchange = (e) => { resolve([...e.target.files]); }; // Resolve with all selected files
      input.click();
    });
  };

  const submitQuestion = async (e) => {
    e.preventDefault();
  
    if (knowledgeBaseContent.length === 0) {
      setKbcUnavailable(true);
      setTimeout(() => { 
        setKbcUnavailable(false);
      }, 10000);
      return;
    }
  
    if (ws && ws.readyState === WebSocket.OPEN) {
      setQuestion('');
      setQaPairs(prevQaPairs => [...prevQaPairs, { question: question, answer: '' }]);
      ws.send(JSON.stringify({ question: question, knowledgeBaseContent: knowledgeBaseContent, subscriptionStatus: currentUser.subscription.isAdvancedUser ? 'advanced' : 'standard' }));
    } else {
      console.error("WebSocket is not connected.");
    }
  };
  
  

  const handleModalToggle = () => {
    setShowUploadModal(!showUploadModal);
    setShowModalSelectionModal(false);
  };

  const handleBulkUploadModalToggle = () => {
    setShowBulkUploadModal(!showBulkUploadModal);
    setShowModalSelectionModal(false);
  };

  const handleInputUrlChange = (event) => {
    setInputUrl(event.target.value);
  };

  const handleChannelUrlChange = (event) => {
    setBatchUploadLink(event.target.value);
  };

  const handleModalSelectionModalToggle = () => {
    setShowModalSelectionModal(!showModalSelectionModal);
  };



  const handleUpload = async () => {
    setUploadStatus('Uploading'); // Set status to uploading
    if (batchUploadLink.length !== 0) {
      await batchUploadVideos(selectedFolder);
    } else if (inputUrl.startsWith('http') && inputUrl.includes('youtu') && inputUrl.includes('watch')) {
      await handleYoutubeSubmission(selectedFolder);
    } else if (inputUrl.startsWith('http')) {
      await handleWebpageSubmission(selectedFolder);
    };
    if (batchUploadLink.length !== 0) {
      setBatchUploadLink('');
      handleBulkUploadModalToggle();
    } else {
      setInputUrl('');
      handleModalToggle();
      fetchFolders();
      fetchAndStoreKnowledgeBase(selectedFolder);
    };
  };

  const uploadFiles = async (folderName) => {
    const files = await selectFiles();
    if (!files.length) return;

    setUploadStatus('Uploading');

    try {
      const promises = files.map(file => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('userEmail', currentUserEmail);
        formData.append('folderName', folderName);
        formData.append('fileName', file.name);

        return axios.post('https://ragobble.com/upload-to-knowledge-base', formData, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      });

      await Promise.all(promises);
      setUploadStatus('Uploaded'); // Set status to uploaded after all files are uploaded
      fetchFolders();
      setShowFileAdded(true);
      fetchAndStoreKnowledgeBase(selectedFolder);
      setTimeout(() => {
        handleModalToggle();  // Close the modal
        setUploadStatus('');         
        setShowFileAdded(false);
      }, 2000);
    } catch (error) {
      console.error('Error uploading files:', error);
      setUploadStatus('Failed to upload files.'); // Update status on failure
      setTimeout(() => setUploadStatus(''), 3000); // Optional: Clear status after 3 seconds
    }
  };

  const handleYoutubeSubmission = async (folderName) => {
    if (!inputUrl) {
      alert('Please enter a YouTube URL.');
      return;
    }

    setUploadStatus('Uploading'); // Set status to uploading

    try {
      await axios.post('https://ragobble.com/upload-youtube-transcription-and-update-kb', {
        youtubeUrl: inputUrl,
        userEmail: currentUserEmail,
        folderName,
      }, 
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });
      fetchFolders();
      setUploadStatus('Uploaded');
      setShowVideoAdded(true);
      fetchAndStoreKnowledgeBase(selectedFolder);
      setTimeout(() => {
        setUploadStatus('');        
        setShowVideoAdded(false);
      }, 2000);
    } catch (error) {
      console.error('Error uploading YouTube transcription:', error);
    }
  };

  const handleWebpageSubmission = async (folderName) => {
    if (!inputUrl) {
        alert('Please enter a URL.');
        return;
    }

    setUploadStatus('Uploading'); // Set status to uploading

    try {
        await axios.post('https://ragobble.com/upload-webpage-transcription-and-update-kb', {
            pageUrl: inputUrl,
            userEmail: currentUserEmail,
            folderName,
        }, 
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });
        setUploadStatus('Uploaded');
        setShowWpAdded(true);
        setTimeout(() => {
          setShowWpAdded(false);
          setUploadStatus('');         
        }, 2000);
        fetchFolders();
        fetchAndStoreKnowledgeBase(selectedFolder);
    } catch (error) {
        console.error('Error uploading webpage transcription:', error);
    }
  };

  useEffect(() => {
    if (folderCount === 0) {
      setQaPairs([]);
    }
  }, [folderCount]);

  useEffect(() => {
    setQaPairs([]);
  }, [selectedFolder]);

 useEffect(() => {
    const scrollToBottom = () => {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    };

    const timeoutId = setTimeout(scrollToBottom, 100); // Adjust the delay as needed

    return () => clearTimeout(timeoutId);
  }, [qaPairs]);
  
  return (
    <>
    <h1 className='desktop-message'>Please Utilize a Laptop or Desktop for the <br></br>Best Experience</h1>
    <div className='gobbler-container'>
      <div className='current-model-signage-container'>
        <img src={sparkles} alt=''></img>
        <p>{currentUser.subscription.isAdvancedUser ? 'GPT 4o' : 'GPT 3.5 Turbo'}</p> 
        <Tooltip title={currentUser.subscription.isAdvancedUser ? 'GPT4o is the latest flagship model from OpenAI. You are getting the most out of your ragobble experience!' : 'In order to utilize GPT 4o, please upgrade your plan.'} placement="top" arrow>
          <img src={aboutIcon} alt=''></img>
        </Tooltip>
      </div>
      
      {showKbMessage && (<Kbcreation />)}
      {showFileDeleteMessage && (<FileDeletion />)}
      {showKbDeleteMessage && (<FolderDeletion />)}
      {showDuplicateKbMessage && (<DuplicateKb />)}
      {showMaxKb && (<MaxKb />)}
      {showFileAdded && (<FileAdded />)}
      {showVideoAdded && (<YtAdded />)}
      {showWpAdded && (<WpAdded />)}
      {kbcUnavailable && (<KbcUnavailable />)}
      <ul className='gobbler-container-knowledge-base-sidebar'>
        <div className='gobbler-container-knowledge-base-sidebar-header'>
        <div className='gobbler-container-knowledge-base-sidebar-header-inner'>
          <h4>Knowledge Bases</h4>
          <div className='gobbler-container-knowledge-base-sidebar-header-button-container'>
            <Tooltip title="Create Knowledge Base" placement="bottom" arrow>
              <button onClick={() => setShowCreateKbModal(true)} className='plus-kb-button' style={folderCount === 0 ? {boxShadow: '0 0 10px 5px #00ffe1'} : {}}>+</button>
              <Modal open={showCreateKbModal} onClose={() => setShowCreateKbModal(false)}
                BackdropProps={{
                  style: { opacity: 0.15 }
              }}>
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 400,
                  bgcolor: 'background.paper',
                  border: 'none',
                  borderRadius: '4px',
                  boxShadow: 'none',
                  p: 4
                }}>
                  <form onSubmit={createKnowledgeBase}>
                    <input
                      id="name"
                      value={newKbName}
                      onChange={handleKbNameChange}
                      placeholder='Knowledge Base Name'
                    />
                    <button
                      type="submit"
                      className='create-kb-button-submit'
                    >
                      Create
                    </button>
                  </form>
                </Box>
              </Modal>
            </Tooltip>
            <Tooltip title={folderCount === 0 ? 'No Knowledge-Bases to Edit' : 'Edit Knowledge Bases'} placement="bottom" arrow>
              <button className={isEditing && folderCount > 0 ? 'edit-button-editing' : 'edit-button'} onClick={toggleKbEdit} disabled={folderCount === 0}>
                <img src={pencil} alt='' className='pencil'></img>
              </button>
            </Tooltip>
          </div>
          </div>
        </div>
        {folderCount === 0 && (<><NewUserKB /></>)}
        {folders.map((folder, index) => (
          <div className={`database-container`} onClick={(e) => handleFolderSelect(e, folder.name)} style={{ cursor: 'pointer' }}>
            <li className={`${selectedFolder === folder.name ? 'selected-folder' : 'gobbler-container-knowledge-base-sidebar-li'}`} key={index}>
              <Tooltip title={folder.name.length > 14 ? folder.name : ''} placement="left" arrow>
              <div className='gobbler-container-knowledge-base-sidebar-li-top'>
                {folder.name.substring(0, 14) + `${folder.name.length > 14 ? "..." : ""}`}
                <div className='gobbler-container-knowledge-base-sidebar-li-top-buttons-container'>
                  <Modal open={showUploadModal} onClose={handleModalToggle} onClick={(e) => { e.stopPropagation(); }}
                    BackdropProps={{
                      style: { opacity: 0.25 }
                  }}>
                    <Box sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 400,
                      bgcolor: 'background.paper',
                      border: 'none',
                      borderRadius: '5px',
                      boxShadow: 'none',
                      opacity: '500%',
                      p: 5
                    }}>
                      {uploadStatus === '' ? 
                      <>
                      <button
                      className='standard-upload-modal-button'
                        onClick={(e) => {
                          e.stopPropagation();
                          uploadFiles(selectedFolder);
                        }}
                      >
                        Upload File
                      </button>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <small style={{ fontFamily: 'Inter Regular', padding: '5px', paddingTop: '7.5px' }}>or</small>
                      </div>
                      <input
                        className='standard-upload-modal-textarea'
                        placeholder='URL'
                        value={inputUrl}
                        onChange={handleInputUrlChange}
                      />
                      <button
                        onClick={handleUpload}
                        className='standard-upload-modal-button-submit'
                      >
                        Done
                      </button>
                      </>
                      :
                      uploadStatus === 'Uploading' ? 
                      <div className='upload-file-loader-success' style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img src={uploadLoader} alt='' style={{ width: '100px' }}></img>
                      </div>
                      : uploadStatus === 'Uploaded' ? 
                      <div className='upload-file-loader-success' style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img src={uploadSuccess} alt='' style={{ width: '100px' }}></img>
                      </div>
                      : 
                      <div className='upload-file-loader-success' style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <p>Error Uploading File</p>
                      </div>
                      }
                    </Box>
                  </Modal>
                  <Modal open={showBulkUploadModal} onClose={handleBulkUploadModalToggle} onClick={(e) => { e.stopPropagation(); }}
                    BackdropProps={{
                      style: { opacity: 0.15 }
                  }}>
                  <Box sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 400,
                      bgcolor: 'background.paper',
                      border: 'none',
                      borderRadius: '5px',
                      boxShadow: 'none',
                      opacity: '500%',
                      p: 5
                    }}>
                      {uploadStatus === '' ? 
                      <>
                      <input
                        className='channel-upload-modal-input'
                        placeholder='Channel URL'
                        value={batchUploadLink}
                        onChange={handleChannelUrlChange}
                      />
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      </div>
                      <button
                        onClick={handleUpload}
                        className='channel-upload-modal-button-submit'
                      >
                        Done
                      </button>
                      </>
                      :
                      uploadStatus === 'Uploading' ? 
                      <div className='upload-file-loader-success' style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img src={uploadLoader} alt='' style={{ width: '100px' }}></img>
                      </div>
                      : uploadStatus === 'Uploaded' ? 
                      <div className='upload-file-loader-success' style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img src={uploadSuccess} alt='' style={{ width: '100px' }}></img>
                      </div>
                      : 
                      <div className='upload-file-loader-success' style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <p>Error Uploading File</p>
                      </div>
                      }
                    </Box>
                  </Modal>
                  <Modal open={showModalSelectionModal} onClose={handleModalSelectionModalToggle} onClick={(e) => { e.stopPropagation(); }}
                  BackdropProps={{
                    style: { opacity: 0.15 }
                }}
                   >
                  <Box sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '50vw',
                      height: '50vh',
                      backgroundColor: '#ffffff',
                      border: 'none',
                      borderRadius: '5px',
                      boxShadow: 0, // Adjust the RGBA values for lighter shadow
                      opacity: '500%',
                      p: 5,
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}>
                      <h1 className='modal-selection-container-file-upload-container-h1'>Choose Data Type</h1>
                      <div className='modal-selection-container-file-upload-container-outer'>
                      <div className='modal-selection-container-file-upload-container'>
                        <h4>Files, Links, Videos</h4>
                        <small style={{ fontFamily: 'Inter Regular' }}>Examples:</small>
                        <img src={ytUploadEx} alt=''></img>
                        <img src={linkUploadEx} alt=''></img>
                        <small style={{ fontFamily: 'Inter Regular' }}>or</small>
                        <div className='modal-selection-container-file-upload-container-file-types'>
                          <img src={pdfPic} alt=''></img>
                          <img src={docxPic} alt=''></img>
                          <img src={txtPic} alt=''></img>
                        </div>
                        <button
                          onClick={handleModalToggle}
                        >
                          Select
                        </button>
                      </div>
                      <div className='modal-selection-container-ytchannel-upload-container'>
                      <h4>YouTube Channel</h4>
                      <small style={{ fontFamily: 'Inter Regular', padding: '5px', paddingTop: '7.5px' }}>( Uploads 20 Latest Videos )</small>
                      <img src={channelUploadEx} alt=''></img>
                        <button
                          onClick={handleBulkUploadModalToggle}
                        >
                          Select
                        </button>
                      </div>
                      </div>
                    </Box>
                  </Modal>
                  <Tooltip title="Upload" placement="top" arrow>
                    <button disabled={isEditing} onClick={handleModalSelectionModalToggle} className='gobbler-container-knowledge-base-sidebar-li-top-buttons-container-standard-button-upload'>
                      Upload
                    </button>
                  </Tooltip>
                  <Tooltip title="Show Data" placement="top" arrow>
                    <button onClick={(e) => {
                        e.stopPropagation();  // Stop the event from bubbling up to the container
                        toggleFolder(folder.name);
                      }}
                      className='gobbler-container-knowledge-base-sidebar-li-top-buttons-container-standard-button'
                      disabled={isEditing}
                      >
                      <img className={`kb-selector-img ${folder.expanded ? 'rotate' : ''}`} src={modernArrow} alt="Toggle"></img>
                    </button>
                  </Tooltip>
                  {isEditing && (
                    <Tooltip title="Delete" placement="top" arrow>
                      <button 
                        onClick={() => deleteKnowledgeBase(folder.name)}
                        className='delete-folder-button'
                      >
                      <img src={trash} alt=''></img>
                      </button>
                    </Tooltip>
                  )}
                </div>
              </div>
              </Tooltip>
              <ul style={{ display: folder.expanded ? 'contents' : 'none' }} className='gobbler-container-knowledge-base-sidebar-li-files-ul'>
                <label className='files-label'>
                  Files
                </label>
                <div className='gobbler-container-knowledge-base-sidebar-li-files-ul-li-scroll-container'>
                {folder.files
                  .filter(file => file && file.trim() !== 'knowledge-base.txt' && file.trim() !== '')
                  .map((file, fileIndex) => (
                  <li key={fileIndex} className='gobbler-container-knowledge-base-sidebar-li-files-ul-li'>
                    <Tooltip title={file.startsWith('YOUTUBE_') ? file.substring(8, file.length - 16) : s3FilenameToUrl(file)} placement="top" arrow>
                      <a href={file.startsWith('YOUTUBE_') ? `https://youtube.com/watch?v=${file.substring(file.length - 15, file.length - 4)}` : `${s3FilenameToUrl(file)}`} target='_blank' rel='noreferrer'>
                        {file.length > 15 && file.startsWith('YOUTUBE_') ? (file.substring(8, file.length - 16)).substring(0, 15) : file.length > 15 ? s3FilenameToUrl(file).substring(0, 15) : file}
                      </a>
                    </Tooltip>
                    <Tooltip title="Delete File" placement="right" arrow>
                      <button onClick={(e) => {
                        e.stopPropagation();
                        deleteFile(folder.name, file);
                      }}
                      className='remove-file-button'
                      >
                        —
                      </button>
                    </Tooltip>
                  </li>
                ))}
                </div>
              </ul>
            </li>
          </div>
        ))}
      </ul>
      <div className='gobbler-container-inference-section'>
      <div className="question-asker-container" ref={containerRef}>
        {folderCount === 0 && (<><GetStarted /></>)}
        {qaPairs.map(({ question, answer }, index) => (
          <div key={index} style={{ width: '100%' }}>
            <div className='gobbler-question-container'>
              <div className='gobbler-question-inner-container'>
                <div className='gobbler-question-container-image-container'>
                  <img src={avatar} className='ai-message-logo' alt=''></img>
                </div>
                <br></br>
                <ReactMarkdown
                  children={question}
                  remarkPlugins={[remarkGfm]}
                  className='gobbler-question'
                  components={{
                    ul: ({ node, ...props }) => <ul style={{ listStyleType: 'disc', marginLeft: '20px', fontFamily: 'Inter Light' }} {...props} />,
                    p: ({ node, ...props }) => <p style={{ marginBottom: '16px', fontFamily: 'Inter Light' }} {...props} />,
                    ol: ({ node, ...props }) => <ul style={{ listStyleType: 'square', marginLeft: '20px', fontFamily: 'Inter Light' }} {...props} />,
                    h3: ({ node, ...props }) => <h3 style={{ fontFamily: 'Inter Light' }} {...props} />,
                    h4: ({ node, ...props }) => <h4 style={{ fontFamily: 'Inter Light', marginBottom: '16px' }} {...props} />
                  }}
                />
              </div>
            </div>
            <div className='gobbler-answer-container'>
              <div className='gobbler-answer-container-image-container'>
                <img src={Logo} className='ai-message-logo' alt=''></img>
              </div>
              <br></br>
              <ReactMarkdown
                children={answer}
                remarkPlugins={[remarkGfm]}
                className='gobbler-answer'
                components={{
                    ul: ({ node, ...props }) => (
                        <ul style={{ listStyleType: 'disc', fontFamily: 'Inter Light', margin: '10px 0', paddingLeft: '20px' }} {...props} />
                      ),
                      li: ({ node, ...props }) => (
                        <li style={{ fontFamily: 'Inter Light', margin: '5px 0' }} {...props} />
                      ),
                      p: ({ node, ...props }) => (
                        <p style={{ fontFamily: 'Inter Light', margin: '10px 0', lineHeight: '1.6' }} {...props} />
                      ),
                      ol: ({ node, ...props }) => (
                        <ol style={{ listStyleType: 'decimal', fontFamily: 'Inter Light', margin: '10px 0', paddingLeft: '20px' }} {...props} />
                      ),
                      h3: ({ node, ...props }) => (
                        <h3 style={{ fontFamily: 'Inter Light', fontWeight: 'bold', fontSize: '1.25em', margin: '15px 0' }} {...props} />
                      ),
                      h4: ({ node, ...props }) => (
                        <h4 style={{ fontFamily: 'Inter Light', fontWeight: 'bold', fontSize: '1.1em', margin: '15px 0' }} {...props} />
                      ),
                      blockquote: ({ node, ...props }) => (
                        <blockquote style={{ fontFamily: 'Inter Light', fontStyle: 'italic', margin: '10px 0', paddingLeft: '15px', borderLeft: '3px solid #ccc', color: '#666' }} {...props} />
                      ),
                }}
              />
            </div>
          </div>
        ))}
        {(qaPairs.length === 0 && folderCount > 0) && (
          <div className='sac-message'>
            <a href="/"><img src={Logo} className='sac-message-logo' alt=''></img></a>
            <h1 className='sac-h1'>How can I help you today?</h1>
            <div className='sac-selected-kb'>
              <img src={kbIcon} alt=''></img>
              <p> : </p>
              <p>{selectedFolder}</p>
            </div>
          </div>
        )}
      </div>
      <Tooltip
          title={isEditing ? 'Cannot Access While Editing Knowledge Bases' : folderCount === 0 ? 'Please Create A Knowledge-Base to Ask Questions' : ''}
          placement="left"
          arrow
        >
          <div className='inference-input'>
            <textarea
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Ask a question..."
              disabled={isEditing || folderCount === 0}
              rows={1}
              className='inference-textarea'
              onKeyDown={(e) => {
                if (e.key === 'Enter' && question) {
                  e.preventDefault();
                  submitQuestion(e);
                }
              }}
            />
            <Tooltip title={!question ? "Type a message to send" : "Send message"} placement="top" arrow>
              <button onClick={submitQuestion} disabled={!question}>
                <img src={upArrow} alt="" className='inference-send-button-img' />
              </button>
            </Tooltip>
          </div>
        </Tooltip>
    </div>
    <PopupImageComponent />
    </div>
    </>
  );
};

export default GobbleComponent;