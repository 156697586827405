import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Unsubscribe() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setError(false); // Reset error state on new input
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!email) {
            setError(true);
            setMessage('Please enter your email to unsubscribe.');
            return;
        }

        try {
            const response = await axios.post('https://ragobble.com/api/unsubscribe', { email });
            setMessage(response.data.message);
            setError(false);
            setTimeout(() => {
                navigate('/');
            }, 2000);
        } catch (error) {
            setError(true);
            setMessage(error.response ? error.response.data.message : 'Failed to unsubscribe.');
        }
    };

    return (
        <div className='unsub-container'>
            <form onSubmit={handleSubmit}>
                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Enter your email"
                />
                <button type="submit">Unsubscribe</button>
            </form>
            {message && <p style={{ color: error ? 'red' : 'green' }}>{message}</p>}
        </div>
    );
}

export default Unsubscribe;
