import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import '../styles/Signup.css';
import Signupform from '../components/Signupform';
import { useAuth } from '../auth/AuthContext';

const Signup = () => {
  const { currentUser } = useAuth();

  useEffect(() => {
    // Check if the user is already logged in
    if (currentUser) {
      // Redirect to another route (e.g., home)
      window.location.href = '/'; // Change "/" to your desired route
    }
  }, [currentUser]);

  return (
    <>
      <Helmet>
        <title>ragobble - Sign Up</title>
        <meta name="description" content="Create a new account on ragobble and get started today." />
        <link rel="canonical" href="https://ragobble.com/signup" />
      </Helmet>
      <div>
        <main>
          <section className='background-container'>
            <section className='all-minus-footer'>
              <Navigation />
            </section>      
              <Signupform />
          </section>
        </main>
      </div>
    </>
  );
}

export default Signup;
