import React from 'react';
import { Helmet } from 'react-helmet';
import Navigation from './components/Navigation';
import Hero from './components/Hero';
import Footer from './components/Footer';
import HowItWorks from './components/HowItWorks';
import Pricetiles from './components/Pricetiles';
import Faqcomponent from './components/Faqcomponent';
import './font-preload.css';
import CurrentTools from './components/CurrentTools';
import Bottombanner from './components/Bottombanner';
import Launches from './components/Launches';

const App = () => {
  return (
    <div className='app-cont'>
      <Helmet>
        <title>ragobble - Homepage</title>
        <meta name="description" content="Ragobble is a platform that allows users to upload data and use it with Large-Language-Models (LLMs) to create Knowledge-Bases and get AI-assisted answers." />
        <link rel="canonical" href="https://www.ragobble.com" />
      </Helmet>
      <Navigation />
      <main>
        <Hero />
        <div className='background-container'>
          <HowItWorks />
          <CurrentTools />
          <Pricetiles />
        </div>
        <Bottombanner />
        <Faqcomponent />
      </main>
      <Footer />
    </div>
  );
}

export default App;
