import React from 'react';
import '../styles/FileDeletion.css';
import { Fade } from 'react-reveal';

const FolderDeletion = () => {
  return (
        <div className='file-deletion-container'>
            <Fade>
                <label>Knowledge-Base Deleted Successfully!</label>
            </Fade>
        </div>
  );
};

export default FolderDeletion;
