import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/Bottombanner.css';
import { Link } from 'react-router-dom';
import { Fade } from 'react-reveal';

const Launches = () => {
  return (
    <div className='taaft-banner-container'>
      <Helmet>
        <meta name="description" content="Join Ragobble today for free and explore our AI-driven tools. Sign up now to create knowledge bases, upload files, and ask questions effortlessly." />
        <meta name="keywords" content="Ragobble, free signup, AI tools, knowledge base, upload files" />
        <meta name="author" content="Ragobble" />
        <meta property="og:title" content="ragobble - Start Today For Free" />
        <meta property="og:description" content="Join Ragobble today for free and explore our AI-driven tools. Sign up now to create knowledge bases, upload files, and ask questions effortlessly." />
        <meta property="og:image" content="/path/to/start-today-image.jpg" />
        <meta property="og:url" content="https://www.ragobble.com/signup" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ragobble - Start Today For Free" />
        <meta name="twitter:description" content="Join Ragobble today for free and explore our AI-driven tools. Sign up now to create knowledge bases, upload files, and ask questions effortlessly." />
        <meta name="twitter:image" content="/path/to/start-today-image.jpg" />
      </Helmet>
      <a href="https://theresanaiforthat.com/ai/ragobble/?ref=featured&v=1495001" target="_blank" rel="nofollow"><img src="https://media.theresanaiforthat.com/featured-on-taaft.png?width=600"></img></a>
    </div>
  )
}

export default Launches;
