import React from 'react';
import '../styles/FileDeletion.css';
import { Fade } from 'react-reveal';

const Kbcreation = () => {
  return (
        <div className='file-deletion-container'>
            <Fade>
                <label>Knowledge Base Created!</label>
            </Fade>
        </div>
  );
};

export default Kbcreation;
