import React, { createContext, useContext, useState } from 'react';

// Create the context
const WorkbenchContext = createContext();

// Provide the context
export const AppProvider = ({ children }) => {
  const [selectedComponent, setSelectedComponent] = useState('rag-workbench');

  // The value that will be supplied to any descendants of this component.
  const value = { selectedComponent, setSelectedComponent };

  return <WorkbenchContext.Provider value={value}>{children}</WorkbenchContext.Provider>;
};

// Use this hook in components to access the context
export const useWorkbenchContext = () => useContext(WorkbenchContext);
