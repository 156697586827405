import React, { useState } from 'react';
import questionMark from '../assets/question-mark.svg';
import ResourcesDropdown from './ResourcesDropdown';
import { useNavigate } from 'react-router-dom';
import message from '../assets/envelope.svg';

import about from '../assets/aboutUs.svg';
import tosPic from '../assets/tosPic.svg';
import privacyPic from '../assets/privacyPic.svg';


const PopupImageComponent = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const navigate = useNavigate();
  const handleImageClick = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <div style={{ position: 'absolute', bottom: '1%', right: '1%' }}>
      <p
        style={{ 
            cursor: 'pointer', 
            width: '25px',
            fontFamily: 'IBM Plex Mono Light', 
            color: 'black', 
            border: '1px solid black',
            height: '25px', 
            borderRadius: '50%', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center' }}
        onClick={handleImageClick}
      >?</p> 
      {isPopupVisible && (
        <div 
          style={{
            position: 'absolute',
            bottom: '220px',  // Adjust as needed
            right: '15px',
          }}
        >
          <section className={`logins-dropdown2 ${isPopupVisible ? 'visible' : ''}`}>
                <button className='dropdown-element2' onClick={() => { window.location.href='mailto:admin@ragobble.com'; }} >
                    <label>Support</label> 
                    <img src={message} alt='logout'></img>
                </button>
                <button className='dropdown-element2' onClick={() => { navigate('/tos') }} >
                    <label>Terms of Service</label> 
                    <img src={tosPic} alt='logout'></img>
                </button>
                <button className='dropdown-element2' onClick={() => { navigate('/privacy') }} >
                    <label>Privacy Policy</label> 
                    <img src={privacyPic} alt='logout'></img>
                </button>
        </section>
        </div>
      )}
    </div>
  );
};

export default PopupImageComponent;
