import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/CurrentTools.css';
import demoVideo from '../assets/demo-final.mp4';
import sumVideo from '../assets/summary.mp4';
import vftool from '../assets/vftooldemo.mp4';
import chatbotdemo from '../assets/chatbotdemo.mp4';
import { Fade } from 'react-reveal';

const CurrentTools = () => {
  return (
    <div className='current-tools-container'>
      <Helmet>
        <meta name="description" content="Discover Ragobble's current AI tools. Create knowledge bases, upload various media, ask questions, and summarize content effortlessly." />
        <meta name="keywords" content="AI tools, knowledge base, upload files, summarize, Ragobble" />
        <meta name="author" content="Ragobble" />
        <meta property="og:title" content="ragobble - Current Tools" />
        <meta property="og:description" content="Discover Ragobble's current AI tools. Create knowledge bases, upload various media, ask questions, and summarize content effortlessly." />
        <meta property="og:image" content="/path/to/current-tools-image.jpg" />
        <meta property="og:url" content="https://www.ragobble.com/current-tools" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ragobble - Current Tools" />
        <meta name="twitter:description" content="Discover Ragobble's current AI tools. Create knowledge bases, upload various media, ask questions, and summarize content effortlessly." />
        <meta name="twitter:image" content="/path/to/current-tools-image.jpg" />
      </Helmet>
      <Fade cascade delay={250}>
      <h1 className='oct-h1'>Our Current Tools</h1>
      <div className='current-tools-tile'>
        <div className='current-tools-tile-content'>
          <h4>RAG Playground</h4>
          <ul>
            <li>Create a Knowledge Base</li>
            <li>Upload Files, Videos, Books, Links, Data.</li>
            <li>Ask Questions!</li>
          </ul>
        </div>
        <video src={demoVideo} autoPlay loop muted playsInline></video>
      </div>
      <div className='current-tools-tile-v2'>
        <video src={sumVideo} autoPlay loop muted playsInline></video>
        <div className='current-tools-tile-content'>
          <h4>Summary Tool</h4>
          <ul>
            <li>Upload a File, URL or Video</li>
            <li>Choose a Word Count and Focal Point</li>
            <li>Summarize!</li>
          </ul>
        </div>
      </div>
      <div className='current-tools-tile'>
        <div className='current-tools-tile-content'>
          <h4>Video Finder Tool</h4>
          <ul>
            <li>YouTube Channel URL</li>
            <li>Choose a Topic</li>
            <li>Find Every Related Video</li>
          </ul>
        </div>
        <video src={vftool} autoPlay loop muted playsInline></video>
      </div>
      <div className='current-tools-tile-v2'>
        <video src={chatbotdemo} autoPlay loop muted playsInline></video>
        <div className='current-tools-tile-content'>
          <h4>ChatBot</h4>
          <ul>
            <li>Quick and Easy Single Use RAG Bot</li>
            <li>Upload A File, URL, or Video</li>
            <li>Chat With It!</li>
          </ul>
        </div>
      </div>
      <h1 className='oct-h1-2'>We're currently adding new tools each week!</h1>
      </Fade>
    </div>
  );
};

export default CurrentTools;
