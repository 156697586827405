import React, { useState } from 'react';
import '../styles/Faq.css';
import arrowImage from '../assets/plus-faq.svg';

const Faqcomponent = () => {
  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const faqData = [
    {
      question: 'What is ragobble?',
      answer: [
        'ragobble is a platform which allows users to upload data, and then use that data with Large-Language-Models (LLMs). Users can create "Knowledge-Bases" which are corpuses of information created from links, files, or videos which can then serve as a reference for AI when the user asks a question. This process is called Retrieval Augmented Generation (RAG). In a nutshell, users can upload any file, URL, article, book, or video, store a bunch of them in a Knowledge-Base, and then ask AI questions about this information or allow AI to use it as a reference when generating an answer.',
        'RAG allows for LLMs to bypass the constraint of time when it comes to training data and allows models to even reference information and data which was only created seconds ago and incorporate that information in the process of assisting users.'
      ],
    },
    {
      question: 'How does it work?',
      answer: [
        'It\'s simple! Visit the <a href="/workbench">Workbench</a> page. Create and name a "Knowledge-Base". Then, you can upload YouTube links, article links, or files to your Knowledge-Base. Then you\'re all set, ask any questions and you\'ll get responses from AI equipped with the Knowledge/Data you provided.',
        'There are a lot of features coming down the pipeline, however, we are currently a one-person team and are actively looking to obtain funding.'
      ],
    },
    {
      question: 'How many Knowledge-Bases can I have?',
      answer: [
        'We offer three different tiers, each which have a different limit on the amount of "Knowledge-Bases" a user can have. Visit the <a href="/pricing">Pricing</a> page for more information on the breakdown of different tiers.'
      ],
    },
    {
      question: 'I need more help.',
      answer: [
        'If you need additional assistance, please feel free to contact support <a href="mailto:support@ragobble.com">here</a>.'
      ],
    },
  ];

  return (
    <section className='faq-container'>
      <h1>Frequently Asked Questions (FAQ)</h1>
      <section className='accordion'>
        {faqData.map((item, index) => (
          <div className='accordion-item' key={index}>
            <button
              onClick={() => toggleAccordion(index)}
              className={`accordion-question ${openAccordion === index ? 'open' : ''}`}
              aria-expanded={openAccordion === index}
              aria-controls={`faq-content-${index}`}
            >
              {item.question}
              <img
                src={arrowImage}
                alt={openAccordion === index ? 'Collapse' : 'Expand'}
                className={`accordion-icon ${openAccordion === index ? 'rotate' : ''}`}
                style={{width: '15px', margin: '0'}}
              />
            </button>
            <ul
              id={`faq-content-${index}`}
              className='accordion-answer'
              style={{ maxHeight: openAccordion === index ? '250px' : '0' }}
            >
              {item.answer.map((bullet, i) => (
                <li
                  key={i}
                  dangerouslySetInnerHTML={{ __html: bullet }} // Render HTML content, including links
                ></li>
              ))}
            </ul>
          </div>
        ))}
      </section>
    </section>
  );
};

export default Faqcomponent;
