import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signup from './routes/Signup';
import Signin from './routes/Signin';
import { AuthProvider } from './auth/AuthContext';
import AuthenticatedRoute from './routes/AuthenticatedRoute';
import About from './routes/About';
import VerificationSuccess from './routes/VerificationSuccess';
import Pricing from './routes/Pricing';
import Workbench from './routes/Workbench';
import PrivacyPolicyRoute from './routes/PrivacyPolicyRoute';
import TosRoute from './routes/TosRoute';
import UnsubscribeRoute from './routes/UnsubscribeRoute';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="signup" element={<Signup />} />
          <Route path="signin" element={<Signin />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="about" element={<About />} />
          <Route path="privacy" element={<PrivacyPolicyRoute />} />
          <Route path="tos" element={<TosRoute />} />
          <Route path="unsubscribe" element={<UnsubscribeRoute />} />
          <Route path="verificationsuccess" element={<VerificationSuccess />} />
          <Route path="workbench" element={<AuthenticatedRoute component={Workbench} />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
