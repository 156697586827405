import React from 'react';
import '../styles/About.css';
import backarrow from '../assets/about-back-arrow.svg';
import { Fade } from 'react-reveal';
import { Helmet } from 'react-helmet';

const Aboutpage = () => {
    return (
        <div className='about-container'>
            <Helmet>
                <title>ragobble - About</title>
                <meta name="description" content="Learn about ragobble, our mission to create a gold standard platform for utilizing Retrieval Augmented Generation in daily workloads, and how you can join our team." />
            </Helmet>
            <div className='about-left'>
                <Fade delay={1000}>
                    <div className='arrow-container'>
                        <a href='/'><img src={backarrow} alt='Back to home'></img></a>
                    </div>
                </Fade>
                <div className='about-text'>
                    <Fade>
                        <h1>About ragobble</h1>
                    </Fade>
                    <Fade delay={250}>
                        <p>Our mission is to make a platform that serves as the gold standard for users to utilize Retrieval Augmented Generation in their daily workload. Every day, billions of individuals browse the internet either for entertainment, media consumption, learning, research, and more. Our product streamlines all of these uses by giving users another dimension of interpretation and comprehension for their needs. Our product is currently in its infancy stages, and we plan to incorporate many different new methods of utilizing RAG for everyday or business use-cases. Although we are currently a one-person team, we are looking to grow rapidly.</p>
                        <p>If you are interested in joining our team, feel free to reach out to <a href="mailto:admin@ragobble.com">admin@ragobble.com</a> with your resume.</p>
                    </Fade>
                </div>
            </div>
            <footer style={{ fontFamily: 'IBM Plex Mono Light', color: 'black', width: '100%', textAlign: 'center', fontSize: '0.65rem' }}>
                text + images + code © ragobble 2024   ·   All Rights Reserved
            </footer>
        </div>
    );
};

export default Aboutpage;
