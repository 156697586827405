import React from 'react';
import { Helmet } from 'react-helmet';
import { Fade } from 'react-reveal';
import mic from '../assets/mic.svg';
import transcribe from '../assets/transcribe.svg';
import kb from '../assets/kb.svg';
import brain from '../assets/brain.svg';
import '../styles/HowItWorks.css';

const HowItWorks = () => {
  return (
    <div className="full-container">
      <Helmet>
        <title>ragobble - Enhance Your Research</title>
        <meta name="description" content="Discover how Ragobble empowers academic research with AI. Organize your scholarly resources and let AI assist you in extracting and analyzing information." />
        <meta name="keywords" content="academic research, AI, scholarly resources, research papers, Ragobble" />
        <meta name="author" content="Ragobble" />
        <meta property="og:title" content="ragobble - Enhance Your Research" />
        <meta property="og:description" content="Discover how Ragobble empowers academic research with AI. Organize your scholarly resources and let AI assist you in extracting and analyzing information." />
        <meta property="og:image" content="/path/to/social-image.jpg" />
        <meta property="og:url" content="https://www.ragobble.com/how-it-works" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ragobble - Enhance Your Research" />
        <meta name="twitter:description" content="Discover how Ragobble empowers academic research with AI. Organize your scholarly resources and let AI assist you in extracting and analyzing information." />
        <meta name="twitter:image" content="/path/to/social-image.jpg" />
      </Helmet>
      <Fade cascade>
        <section className="title-section">
          <h1>Advanced AI for<br />Academic Research</h1>
          <p>Upload scholarly articles, research papers, books, lectures, audio, or videos and let AI help you uncover insights.</p>
        </section>
      </Fade>
      <Fade cascade delay={250}>
        <section className="tiles-container">
          <article className="tile">
            <div className="tile-top-section">
              <h4>Create A Custom<br /> AI Knowledge-Base</h4>
              <img src={kb} alt="Knowledge base icon" />
            </div>
            <small className="transcribe-small3">Create and name a Knowledge-Base to store your data (videos, URLs, Files, PDFs, etc). This corpus of information will be referenced by AI when you ask it questions.</small>
          </article>
          <article className="tile">
            <div className="tile-top-section">
              <h4>Upload Videos, Links<br />or Files</h4>
              <img src={mic} alt="Microphone icon" />
            </div>
            <small className="transcribe-small1">You Can Upload:</small>
            <ul>
              <li>Articles</li>
              <li>Videos</li>
              <li>Books</li>
              <li>Papers</li>
              <li>Webpages</li>
            </ul>
          </article>
        </section>
      </Fade>
      <Fade cascade delay={500}>
        <section className="tile-bottom-row">
          <article className="tile">
            <div className="tile-top-section">
              <h4>Seamless Data<br />Vectorization</h4>
              <img src={transcribe} alt="Transcription icon" />
            </div>
            <small className="transcribe-small2">Our platform easily formats your data and gives it to LLMs to reference during your searches.</small>
          </article>
          <article className="tile">
            <div className="tile-top-section">
              <h4>Ask AI Any Question</h4>
              <img src={brain} alt="Brain icon" />
            </div>
            <small className="transcribe-small3">Ask any questions you have about the uploaded videos, files, or webpages and AI will give you the answer!</small>
          </article>
        </section>
      </Fade>
    </div>
  );
};

export default HowItWorks;
