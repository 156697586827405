import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Pricetiles from '../components/Pricetiles';
import '../styles/Pricing.css';

const Pricing = () => {
  return (
    <>
      <Helmet>
        <title>ragobble - Pricing</title>
        <meta name="description" content="Discover our pricing plans and choose the best option for your needs at Ragobble." />
        <link rel="canonical" href="https://ragobble.com/pricing" />
      </Helmet>
      <div>
        <main>
          <Navigation />
          <div className='pricing-route-container'>
            <Pricetiles />
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}

export default Pricing;
