import React from 'react';
import '../styles/Footer.css';
import Logo from '../assets/ragobble.svg';
import useSound from 'use-sound';
import hover from '../assets/tdphover.mp3';
import { Link } from 'react-router-dom';
import { Fade } from 'react-reveal';
import halfsquare from '../assets/arightfooter.svg';
import wrench from '../assets/wrench3.svg';
import xlogo from '../assets/xlogo.svg';

const Footer = () => {
    const [play] = useSound(hover, { volume: 1.0 });
  return (
    <section className='footer-container'>
        <Fade delay={250}>
        <section className='cright-container'>
            <section className='footer-left'>
                <section className='navigation-logo'>
                <Link to="/" style={{textDecoration: 'none'}}>
                <section className='navigation-logo-text'>
                        <img src={Logo} alt="logo"></img>
                    <section className='tdp-title-container'>
                        <h6 className='tdp-first'>ragobble</h6>
                    </section>
                </section>
                </Link>
                </section>
                <section className='pulse-container'>
                <div className='pin2'></div><div className='pin-effect2'></div>
                <p>ALL SYSTEMS OPERATIONAL</p>
                </section>
            </section>
            <small>text + images + code © ragobble 2024   ·   All Rights Reserved</small>
            <a href='/privacy' target='_blank' className='pp-tos-footer'>Privacy Policy</a>
            <a href='/tos' target='_blank' className='pp-tos-footer'>ToS</a>
            <section className='social-container'>
                {/* <a href="https://www.linkedin.com/" style={{textDecoration: 'none'}} target="_blank">
                <svg width="800px" height="800px" viewBox="0 0 24 24" id="meteor-icon-kit__regular-linkedin" fill="" xmlns="http://www.w3.org/2000/svg" className='devicon-linkedin-plain'>
                <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                <g id="SVGRepo_iconCarrier">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.37214 24H0.396429V7.97674H5.37214V24ZM2.88161 5.79102C1.29054 5.79102 0 4.47317 0 2.8821C2.37147e-08 1.29063 1.29014 0.000488281 2.88161 0.000488281C4.47307 0.000488281 5.76321 1.29063 5.76321 2.8821C5.76321 4.47317 4.47214 5.79102 2.88161 5.79102ZM23.9946 24H19.0296V16.2C19.0296 14.341 18.9921 11.9571 16.4427 11.9571C13.8557 11.9571 13.4593 13.9767 13.4593 16.066V24H8.48893V7.97674H13.2611V10.1625H13.3307C13.995 8.90352 15.6177 7.57495 18.0386 7.57495C23.0743 7.57495 24 10.891 24 15.1982V24H23.9946Z" fill=""/>
                </g>
                </svg>
                </a> */}
                <a href="https://twitter.com/ragobble" style={{textDecoration: 'none'}} target="_blank"><svg width="1200" height="1227" viewBox="0 0 1200 1227" fill="" xmlns="http://www.w3.org/2000/svg" className='devicon-twitter-original2'>
                    <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill=""/>
                </svg></a>
            </section>

        </section>
        </Fade>
    </section>
  )
}

export default Footer;







//  <section className='footer-container'>
//  <section className='footer-inner-container'>
//      <section className='footer-socials'>
//          <Link to="/"><img src={Logo} alt='logo'></img></Link>
//          <p>Disclaimer: Our business facilitates connecting buyers and sellers. However, we do not take responsibility for any in-person interactions that may occur as a result of these transactions. We advise all users to take necessary precautions, such as meeting in public places and operating safely. Our business will not be liable for any damages resulting from in-person interactions, and any disputes must be resolved between the parties involved. By using our website, you agree to these terms.</p>
//      </section>
//      <section className='footer-links'>
//          <ul>
//              <li><a href="/transact" onMouseEnter={play}>Buy or Sell</a></li>
//              <li><a href="/howitworks" onMouseEnter={play}>How It Works</a></li>
//              <li><a href="/support" onMouseEnter={play}>Support</a></li>
//              <li><a href="/support" onMouseEnter={play}>Donate</a></li>
//              <li><a href="https:www.twitter.com/cryptodarkpool" target="_blank" rel='noreferrer' onMouseEnter={play}>Follow Us On Twitter</a></li>
//          </ul>
//      </section>
//  </section>
//  <small>Copyright © 2023   ·   TheDarkPool LLC   ·   All Rights Reserved</small>
//  </section>