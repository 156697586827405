import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/ResourcesDropdown.css';
// import wrench from '../assets/wrench3.svg';
import faq from '../assets/question-mark.svg';
import message from '../assets/envelope.svg';
import building from '../assets/building.svg';
import about from '../assets/aboutUs.svg';
import tosPic from '../assets/tosPic.svg';
import privacyPic from '../assets/privacyPic.svg';

function ResourcesDropdown() {
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const showDropdown = () => {
        setDropdownVisible(true);
    };

    const hideDropdown = () => {
        setDropdownVisible(false);
    };

    return (
        <div style={{ textDecoration: 'none', position: 'relative' }}
        onMouseEnter={showDropdown}
        onMouseLeave={hideDropdown}>
            <div className={`caret-icon ${dropdownVisible ? 'show' : ''}`}></div>
            <li style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Resources</li>
            <section className={`resources-dropdown ${dropdownVisible ? 'show' : ''}`}>
                <Link className='resources-link' to='/about'><img src={about} alt='' className='resource-img'></img>About Us</Link>
                <Link className='resources-link' to='mailto:admin@ragobble.com'><img src={message} className='resource-img' alt=''></img>Contact</Link>
                <Link className='resources-link' to='/privacy'><img src={privacyPic} className='resource-img' alt=''></img>Privacy</Link>
                <Link className='resources-link' to='/tos'><img src={tosPic} className='resource-img' alt=''></img>ToS</Link>
            </section>
        </div>
    );
}

export default ResourcesDropdown;
