import React from 'react';
import '../styles/FileDeletion.css';
import { Fade } from 'react-reveal';

const YtAdded = () => {
  return (
        <div className='file-deletion-container'>
            <Fade>
                <label>Video Added to Knowledge Base!</label>
            </Fade>
        </div>
  );
};

export default YtAdded;
