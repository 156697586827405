import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/AuthContext';
import Navigation from '../components/Navigation';
import '../styles/HowItWorks.css';
import Workbenchcomp from '../components/Workbenchcomp';
import Footer from '../components/Footer';
import NavigationV2 from '../components/NavigationV2';
import { AppProvider } from '../components/WorkbenchContext';
import { WebSocketProvider } from '../components/WebSocketStatusContext';


const Workbench = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = 'Workbench';
    setTimeout(() => setLoading(false), 2500);
  }, []);

  return (
    <WebSocketProvider>
      <AppProvider>
        <div>
          <NavigationV2 />
          <Workbenchcomp />
        </div>
      </AppProvider>
    </WebSocketProvider>
  );
};

export default Workbench;


