import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Signinform.css';
import closed from '../assets/closedeye.svg';
import openeye from '../assets/openeye.svg';
import deleteinput from '../assets/delete.svg';
import ErrorMessageOverlay from './ErrorMessageOverlay';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext'; // Import the useAuth hook
import logo from '../assets/ragobble.svg';

const Signinform = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibilityToggle = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleClearInput = (fieldName) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: '',
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSignIn = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('https://ragobble.com/api/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,
          password: formData.password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        login(data.token);
        navigate('/workbench');
        console.log('User logged in:', data);
      } else {
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred while signing in');
    }
  };

  return (
    <section className='signin-container'>
      <section className='signin-left'>
        <form className='signin-form' onSubmit={handleSignIn}>
          <Link to="/" style={{ textDecoration: 'none', width: '82%', margin: '0' }}>
            <section className='tdp-title-container3'>
              <img className='tdp-second3' src={logo} alt='Ragobble logo' />
              <h6 className='tdp-first3'>ragobble</h6>
            </section>
          </Link>
          <h1>Log In</h1>
          <section className='label-ipt-container'>
            <label htmlFor='email'>Email Address</label>
            <div className='input-with-icon'>
              <input
                type='email'
                id='email'
                name='email'
                value={formData.email}
                onChange={handleInputChange}
                aria-label='Email Address'
              />
              {formData.email && (
                <button
                  type='button'
                  className='input-icon2'
                  onClick={() => handleClearInput('email')}
                  aria-label='Clear email input'
                >
                  <img src={deleteinput} alt='Clear input' />
                </button>
              )}
            </div>
          </section>
          <section className='label-ipt-container'>
            <label htmlFor='password'>Password</label>
            <div className='input-with-icon'>
              <input
                type={showPassword ? 'text' : 'password'}
                id='password'
                name='password'
                value={formData.password}
                onChange={handleInputChange}
                aria-label='Password'
              />
              {formData.password && (
                <button
                  type='button'
                  className='input-icon3'
                  onClick={() => handleClearInput('password')}
                  aria-label='Clear password input'
                >
                  <img src={deleteinput} alt='Clear input' />
                </button>
              )}
              <button
                type='button'
                className='password-toggle2'
                onClick={handlePasswordVisibilityToggle}
                aria-label={showPassword ? 'Hide password' : 'Show password'}
              >
                <img src={showPassword ? closed : openeye} alt={showPassword ? 'Close eye' : 'Open eye'} />
              </button>
            </div>
            <small className='forgot-password'>
              <Link to='/forgot-password'>Forgot Password?</Link>
            </small>
          </section>
          <ErrorMessageOverlay message={errorMessage} />
          <button className='login-button' type='submit' aria-label='Log in'>
            Log In
          </button>
          <small className='dhac'>
            Don't have an account? <Link to='/signup'>Get Started</Link>
          </small>
        </form>
      </section>
      <section className='cright'>
        text + images + code © ragobble 2024 · All Rights Reserved
      </section>
    </section>
  );
};

export default Signinform;
