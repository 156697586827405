import React from 'react';
import '../styles/FileDeletion.css';
import { Fade } from 'react-reveal';

const FileAdded = () => {
  return (
        <div className='file-deletion-container'>
            <Fade>
                <label>File Added to Knowledge Base!</label>
            </Fade>
        </div>
  );
};

export default FileAdded;
