import React from 'react';
import '../styles/NewUserKB.css';
import { Fade } from 'react-reveal';

const NewUserKB = () => {
  return (
        <div className='kb-new-user-container'>
            <small>
                Your
                Knowledge-Bases
                Will Display
                Here
            </small>
        </div>
  );
};

export default NewUserKB;
