import React, { createContext, useContext, useState } from 'react';

const WebSocketStatusContext = createContext();

export const useWebSocketStatus = () => useContext(WebSocketStatusContext);

export const WebSocketProvider = ({ children }) => {
  const [status, setStatus] = useState('Disconnected'); // Initial state

  return (
    <WebSocketStatusContext.Provider value={{ status, setStatus }}>
      {children}
    </WebSocketStatusContext.Provider>
  );
};
