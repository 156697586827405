import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Fade } from 'react-reveal';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import tag from '../assets/tag.svg';
import diamond from '../assets/diamond.svg';
import convert from '../assets/convert.svg';
import rate from '../assets/rate.svg';
import rightarrow from '../assets/right-arrow.svg';
import file from '../assets/file.svg';
import star from '../assets/star.svg';
import about from '../assets/about.svg';
import standardclock from '../assets/standard-clock.svg';
import proclock from '../assets/pro-clock.svg';
import mic from '../assets/mic.svg';
import gpt4 from '../assets/node.svg';
import brain from '../assets/brain.svg';
import building from '../assets/building.svg';
import '../styles/Pricing.css';

const Pricetiles = () => {
    const { currentUser } = useAuth();
    const [isSelectedButton, setIsSelectedButton] = useState('monthly');

    const setYearly = () => {
        setIsSelectedButton('yearly');
    };

    const setMonthly = () => {
        setIsSelectedButton('monthly');
    };

    return (
        <div id="pricing-outer">
            <Helmet>

                <meta name="description" content="See how our different price tiers can benefit your needs. Choose between monthly or yearly plans for individual or business needs." />
                <meta name="keywords" content="AI, pricing, subscription, plans, Ragobble" />
                <meta name="author" content="Ragobble" />

                <meta property="og:description" content="See how our different price tiers can benefit your needs. Choose between monthly or yearly plans for individual or business needs." />
                <meta property="og:image" content="/path/to/social-image.jpg" />
                <meta property="og:url" content="https://www.ragobble.com/pricing" />
                <meta name="twitter:card" content="summary_large_image" />

                <meta name="twitter:description" content="See how our different price tiers can benefit your needs. Choose between monthly or yearly plans for individual or business needs." />
                <meta name="twitter:image" content="/path/to/social-image.jpg" />
            </Helmet>
            <div className="pricing-inner">
                <Fade delay={250}>
                    <section className="pricing-text-container">
                        <h1>Pricing</h1>
                        <p>See how our different price tiers can benefit your needs.</p>
                        <div className="beta-message-container">
                            <img src={about} alt="Information icon" />
                            <p className="beta-in-progress">
                                Please reach out <a href="mailto:admin@ragobble.com" style={{ color: 'black', textUnderlineOffset: '3px' }}>here</a> for any billing related inquiries.
                            </p>
                        </div>
                    </section>
                </Fade>
                <section className="yearly-monthly-selector-container">
                    <div className="yearly-monthly-selector-button-container">
                        <button className={isSelectedButton === 'monthly' ? 'selected-pricing-button' : 'unselected-pricing-button'} onClick={setMonthly}>Monthly</button>
                        <button className={isSelectedButton === 'yearly' ? 'selected-pricing-button' : 'unselected-pricing-button'} onClick={setYearly}>Yearly</button>
                    </div>
                </section>
                <section className="pricing-tile-container">
                    <article className="pricing-tile">
                        <div className="pricing-tile-inner">
                            <div className="free-trial-container">
                                <img src={tag} className="pricing-img-title" alt="Tag icon" />
                                <p className="free-trial">7 Day Free Trial !</p>
                            </div>
                            <h4>Standard</h4>
                            <p>Well-suited for individuals looking for day-to-day basic retrieval.</p>
                            <div className="price-amount-container">
                                <h2>{isSelectedButton === 'monthly' ? '$19.99' : '$199.99'}</h2>
                                <small>{isSelectedButton === 'monthly' ? '/ mo' : '/ year'}</small>
                            </div>
                            <ul>
                                <li><img src={brain} alt="Brain icon" /><small>Have up to 3 Knowledge-Bases</small></li>
                                <li><img src={gpt4} alt="GPT-4 icon" /><small>RAG with GPT 3.5 Turbo</small></li>
                                <li><img src={mic} alt="Microphone icon" /><small>Global URL & YouTube Support</small></li>
                            </ul>
                            {currentUser && currentUser.subscription.isFreeUser ? (
                                <Link to={isSelectedButton === 'monthly' ? 'https://buy.stripe.com/dR68ys5hf3g72U8003' : 'https://buy.stripe.com/8wM9CwcJH9Ev3YcdQU'} style={{ textDecoration: 'none' }}>
                                    <button>Get Started <img src={rightarrow} className="ra2" alt="Right arrow" /></button>
                                </Link>
                            ) : currentUser && currentUser.subscription.isStandardUser ? (
                                <Link to="https://billing.stripe.com/p/login/eVa4hpbD00al1Zm9AA" target='_blank' style={{ textDecoration: 'none' }}>
                                    <button>Manage Plan</button>
                                </Link>
                            ) : currentUser && currentUser.subscription.isAdvancedUser ? (
                                <Link to={isSelectedButton === 'monthly' ? 'https://buy.stripe.com/dR68ys5hf3g72U8003' : 'https://buy.stripe.com/8wM9CwcJH9Ev3YcdQU'} style={{ textDecoration: 'none' }}>
                                    <button>Downgrade</button>
                                </Link>
                            ) : (
                                <Link to="/signup" style={{ textDecoration: 'none' }}>
                                    <button>Get Started <img src={rightarrow} className="ra2" alt="Right arrow" /></button>
                                </Link>
                            )}
                        </div>
                    </article>
                    <article className="pricing-tile2">
                        <div className="pricing-tile-inner">
                            <div className="best-value">
                                <img src={diamond} className="pricing-img-title" alt="Diamond icon" />
                                <div className="best-value-text-container">
                                    <img src={star} alt="Star icon" />
                                    <p className="best-value-text">Best Value</p>
                                </div>
                            </div>
                            <h4>Advanced</h4>
                            <p>Suited for individuals with a high volume need for RAG data.</p>
                            <div className="price-amount-container">
                                <h2>{isSelectedButton === 'monthly' ? '$29.99' : '$299.99'}</h2>
                                <small>{isSelectedButton === 'monthly' ? '/ mo' : '/ year'}</small>
                            </div>
                            <ul>
                                <li><img src={brain} alt="Brain icon" /><small>Up to 10 Knowledge-Bases</small></li>
                                <li><img src={gpt4} alt="GPT-4 icon" /><small>GPT 3.5, GPT 4o</small></li>
                                <li><img src={file} alt="File icon" /><small>URL, YouTube, and File Support</small></li>
                            </ul>
                            {currentUser && currentUser.subscription.isFreeUser ? (
                                <Link to={isSelectedButton === 'monthly' ? 'https://buy.stripe.com/14k8ysgZX9EvbqEaEG' : 'https://buy.stripe.com/3cs5mg6lj5ofcuIaEJ'} style={{ textDecoration: 'none' }}>
                                    <button>Get Started <img src={rightarrow} className="ra2" alt="Right arrow" /></button>
                                </Link>
                            ) : currentUser && currentUser.subscription.isStandardUser ? (
                                <Link to={isSelectedButton === 'monthly' ? 'https://buy.stripe.com/14k8ysgZX9EvbqEaEG' : 'https://buy.stripe.com/3cs5mg6lj5ofcuIaEJ'} style={{ textDecoration: 'none' }}>
                                    <button>Upgrade <img src={rightarrow} className="ra2" alt="Right arrow" /></button>
                                </Link>
                            ) : currentUser && currentUser.subscription.isAdvancedUser ? (
                                <Link to="https://billing.stripe.com/p/login/eVa4hpbD00al1Zm9AA" target='_blank' style={{ textDecoration: 'none' }}>
                                    <button>Manage Plan</button>
                                </Link>
                            ) : (
                                <Link to={!currentUser ? '/signup' : isSelectedButton === 'monthly' ? 'https://buy.stripe.com/14k8ysgZX9EvbqEaEG' : 'https://buy.stripe.com/3cs5mg6lj5ofcuIaEJ'} style={{ textDecoration: 'none' }}>
                                    <button>Get Started <img src={rightarrow} className="ra2" alt="Right arrow" /></button>
                                </Link>
                            )}
                        </div>
                    </article>
                    <article className="pricing-tile">
                        <div className="pricing-tile-inner">
                            <img src={building} className="pricing-img-title" alt="Building icon" />
                            <h4>Business</h4>
                            <p>Support for Custom Business Needs</p>
                            <div className="price-amount-container">
                                <h2>Custom</h2>
                            </div>
                            <ul>
                                <li><img src={brain} alt="Brain icon" /><small>Unlimited Knowledge-Bases</small></li>
                                <li><img src={gpt4} alt="GPT-4 icon" /><small>GPT 3.5, GPT 4, Claude and More</small></li>
                                <li><img src={file} alt="File icon" /><small>URL, YouTube, and File Support</small></li>
                            </ul>
                            <Link to="mailto:admin@ragobble.com" style={{ textDecoration: 'none' }}>
                                <button>Contact Us <img src={rightarrow} className="ra2" alt="Right arrow" /></button>
                            </Link>
                        </div>
                    </article>
                </section>
            </div>
        </div>
    );
};

export default Pricetiles;
