import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/HowItWorks.css';
import Aboutpage from '../components/Aboutpage';

const About = () => {

  return (
    <>
      <Helmet>
        <title>About - Ragobble</title>
        <meta name="description" content="Learn more about Ragobble, our mission, and our team." />
        <link rel="canonical" href="https://ragobble.com/about" />
      </Helmet>
      <div>
        <main>
          <Aboutpage />
        </main>
      </div>
    </>
  );
}

export default About;
