import React from 'react';
import '../styles/GetStarted.css';
import { Fade } from 'react-reveal';
import sparkles from '../assets/sparkles.svg';

const GetStarted = () => {
  return (
        <div className='rag-get-started-container'>
            <div className='rag-get-started-container-title'>
                <img src={sparkles}></img>
                <br></br>
                <h1>Get Started</h1>
            </div>
            <ul>
                <li>Create a Knowledge-Base</li>
                <li>Upload Files or Links</li>
                <li>Ask Questions</li>
            </ul>
        </div>
  );
};

export default GetStarted;
