import React, { useEffect } from 'react';

const VerificationSuccess = () => {
  useEffect(() => {
    // Set a timeout to redirect after 3 seconds
    const redirectTimeout = setTimeout(() => {
      // Redirect to the /signin route
      window.location.href = '/signin';
    }, 3000);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(redirectTimeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{
      width: '100%',
      height: '100vh',
      backgroundColor: 'black',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }}>
      <h1 style={{
        color: 'var(--mainblue)',
        fontFamily: 'DM Sans',
        fontWeight: '350'
      }}>Your Email has been verified successfully</h1>
      <br></br>
      <small style={{
        color: 'var(--mainblue)',
        fontFamily: 'Inter Light'
      }}>Click <a href='https://ragobble.com/signin' style={{ color: 'white'}}>Here</a> if you are not redirected automatically.</small>
    </div>
  )
}

export default VerificationSuccess;
