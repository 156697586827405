import React, { useState, useEffect } from 'react';
import '../../styles/ResumeTool.css';
import axios from 'axios';
import { useAuth } from '../../auth/AuthContext.js';
import { useWebSocketStatus } from '../WebSocketStatusContext.js';
import * as pdfjsLib from 'pdfjs-dist';
import uploadfile from '../../assets/upload.svg';
import { Tooltip } from '@mui/material';
import wordsOne from '../../assets/100w.svg';
import wordsTwo from '../../assets/200w.svg';
import wordsThree from '../../assets/300w.svg';
import sparkles from '../../assets/sparkles.svg';
import uploadSuccess from '../../assets/uploadLoader.svg';
import PopupImageComponent from '../WbPopup.jsx';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

const Summarizer = () => {
  const { currentUser, authToken } = useAuth();
  const [ws, setWs] = useState(null);
  const [qaPairs, setQaPairs] = useState([]);
  const { setStatus } = useWebSocketStatus();
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfText, setPdfText] = useState('');
  const [summaryLength, setSummaryLength] = useState(100); // Default to 100 words
  const [url, setUrl] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [displaySummarizeMessage, setDisplaySummarizeMessage] = useState(false);
  const [activeFocus, setActiveFocus] = useState('Summary');

  useEffect(() => {
    function connectWebSocket() {
      const token = localStorage.getItem('authToken');
      const websocket = new WebSocket(`wss://ragobble.com/ws?token=${token}`);

      websocket.onopen = () => {
        setStatus('Connected');
        setWs(websocket);
      };

      websocket.onmessage = (event) => {
        setQaPairs(prevQaPairs => {
          const lastIdx = prevQaPairs.length - 1;
          if (lastIdx >= 0) {
            return [
              ...prevQaPairs.slice(0, lastIdx),
              {
                ...prevQaPairs[lastIdx],
                answer: prevQaPairs[lastIdx].answer + event.data
              }
            ];
          }
          return prevQaPairs;
        });
        // Clear the URL and conversation state after processing the message
        setUrl('');
        setPdfFile(null);
        setPdfText('');
      };

      websocket.onerror = (error) => {
        console.error('WebSocket Error:', error);
      };

      websocket.onclose = () => {
        setStatus('Reconnecting');
        setTimeout(() => {
          connectWebSocket();
        }, 3000);  // Reconnect after 3 seconds
      };

      return () => {
        websocket.close();
        setStatus('Disconnected');
      };
    }

    const cleanup = connectWebSocket();

    // Cleanup function to close the WebSocket when the component unmounts
    return cleanup;
  }, []);

  const handleFileChange = (e) => {
    setQaPairs([]);
    setUrl('');
    setDisplaySummarizeMessage(true);
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      setPdfFile(file);
      convertPdfToText(file);
      setUrl('');  // Clear the URL input if a PDF is uploaded
      setQaPairs([]);
    } else {
      console.error("Please upload a PDF file.");
    }
  };

  const convertPdfToText = (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const typedArray = new Uint8Array(e.target.result);
      const pdf = await pdfjsLib.getDocument(typedArray).promise;
      let text = '';
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        text += textContent.items.map(item => item.str).join(' ');
      }
      setPdfText(text);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleUrlChange = (e) => {
    const newUrl = e.target.value;
    setUrl(newUrl);
    setPdfFile(null);  // Clear the PDF file if a URL is entered
    setPdfText('');
    setQaPairs([]);
    setDisplaySummarizeMessage(newUrl !== ''); // Update displaySummarizeMessage based on whether the URL is empty or not
  };

  const submitQuestion = async (e) => {
    e.preventDefault();
    setQaPairs([]);
    setDisplaySummarizeMessage(false);
    if (ws && ws.readyState === WebSocket.OPEN) {
      const knowledgeBaseContent = url || pdfText;
      const focusPrompts = {
        'Summary': `Summarize the context in ${summaryLength} words.`,
        'Main Idea': `Summarize the context in ${summaryLength} words. Focus on the main idea.`,
        'Key Points': `Summarize the context in ${summaryLength} words. Focus on the key points.`,
        'Action Items': `Summarize the context in ${summaryLength} words. Focus on any action items.`,
        'Quotes': `Summarize the context in ${summaryLength} words. Focus on any quotes.`,
        'Comparisons': `Summarize the context in ${summaryLength} words. Focus on comparisons.`,
        'Recommendations': `Summarize the context in ${summaryLength} words. Focus on any recommendations.`,
      };

      const messagePayload = {
        question: focusPrompts[activeFocus],
        knowledgeBaseContent: knowledgeBaseContent,
        subscriptionStatus: currentUser.subscription.isAdvancedUser ? 'advanced' : 'standard',
        source: 'ResumeTool',
        isUrl: !!url  // Add this field to indicate if the content is a URL
      };

      setQaPairs(prevQaPairs => [...prevQaPairs, { question: messagePayload.question, answer: '' }]);
      ws.send(JSON.stringify(messagePayload));
    } else {
      console.error("WebSocket is not connected.");
    }
  };

  return (
    <div className='resume-tool-container'>
      <form onSubmit={submitQuestion}>
        <input type='text' placeholder='URL' className='summarizer-url-input' value={url} onChange={handleUrlChange}></input>
        <small>or</small>
        <label className="custom-file-upload">
          <Tooltip title="Import PDF" placement="top" arrow>
            <input type='file' accept='application/pdf' onChange={handleFileChange}/>
            <img className='upload' src={uploadfile} alt=''></img>
          </Tooltip>
        </label>
        <div className='summary-buttons-container'>
          <label>Word Count</label>
          <div className='summary-buttons-inner'>
            <Tooltip title="50 Words" placement="top" arrow>
              <button type="button" className={summaryLength === 50 ? 'active' : ''} onClick={() => setSummaryLength(50)}>
                <img src={wordsOne} alt='50 words'></img>
              </button>
            </Tooltip>
            <Tooltip title="100 Words" placement="top" arrow>
              <button type="button" className={summaryLength === 100 ? 'active' : ''} onClick={() => setSummaryLength(100)}>
                <img src={wordsTwo} alt='100 words'></img>
              </button>
            </Tooltip>
            <Tooltip title="200 Words" placement="top" arrow>
              <button type="button" className={summaryLength === 200 ? 'active' : ''} onClick={() => setSummaryLength(200)}>
                <img src={wordsThree} alt='200 words'></img>
              </button>
            </Tooltip>
          </div>
        </div>
        <div className='summary-focus-buttons-container'>
          <label>Focus</label>
          <div className='summary-focus-buttons-container-inner'>
            {['Summary', 'Main Idea', 'Key Points', 'Action Items', 'Quotes', 'Comparisons', 'Recommendations'].map((focus) => (
              <button 
                type="button" 
                key={focus} 
                className={activeFocus === focus ? 'active' : ''} 
                onClick={() => setActiveFocus(focus)}
              >
                {focus}
              </button>
            ))}
          </div>
        </div>
        <button type='submit' disabled={!url && !pdfText} className='summarize-submit-button'>Summarize</button>
      </form>
      <div className='summary-answer-container'>
        {!url && !pdfFile && qaPairs.length === 0 && (
          <div className='summary-answer-container-blank-message'>
            <img src={sparkles} alt='sparkles'></img>
            <h1>Upload a File or URL</h1>
            <p>Use the left pane</p>
          </div>
        )}
      {displaySummarizeMessage && (
        <div className='summary-answer-container-url-uploaded'>
          <img src={uploadSuccess} alt=''></img>
          <p>Click the Summarize Button</p>
        </div>
      )}
      {qaPairs.map((pair, index) => (
        <div key={index} className='summary-answer-inner-container'>
          <div className='summary-answer'>
            {pair.answer}
          </div>
        </div>
      ))}
      </div>
      <PopupImageComponent />
    </div>
  );
};

export default Summarizer;
