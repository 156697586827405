import React from 'react';
import { useWorkbenchContext } from './WorkbenchContext.js'; // Adjust the import path as necessary
import '../styles/NavigationV2.css';
import dbicon from '../assets/db-icon.svg';
import { Fade } from 'react-reveal';
import Logo from '../assets/ragobble.svg';
import summarizerIcon from '../assets/300w.svg';
import { useWebSocketStatus } from './WebSocketStatusContext.js';
import statusPulse from '../assets/statusPulse.svg';
import statusPulse2 from '../assets/statusPulse2.svg';
import yttoolIcon from '../assets/yttool.svg';
import vFinder from '../assets/vFinder.svg';
import chatboticon from '../assets/chatbot.svg';

const NavigationV2 = () => {
    const { selectedComponent, setSelectedComponent } = useWorkbenchContext();
    const { status } = useWebSocketStatus();
    const getButtonClass = (buttonName) => {
        return selectedComponent === buttonName ? 'selectedButton' : 'unselectedButton';
    };

    return (
        <div className="navigationv2">
          <div className='navigationv2-top-container'>
            <div className='logo-v2-container'>
              <a href="/"><img src={Logo} className='logo-v2'></img></a>
            </div>
            <div className='navigationv2-tool-selector'>
                <small>Toolbench</small>
                <button
                    className={getButtonClass('rag-workbench')}
                    onClick={() => setSelectedComponent('rag-workbench')}
                >
                    <img src={dbicon} alt="Gobbler" className='gobbler-img'/>
                    <Fade>
                      <span className="button-text">Playground</span>
                    </Fade>
                </button>
                <button
                    className={getButtonClass('summarizer')}
                    onClick={() => setSelectedComponent('summarizer')}
                >
                    <img src={summarizerIcon} alt="Gobbler" className='gobbler-img'/>
                    <Fade>
                      <span className="button-text">Summarizer</span>
                    </Fade>
                </button>
                <button
                    className={getButtonClass('title-tool')}
                    onClick={() => setSelectedComponent('title-tool')}
                >
                    <img src={vFinder} alt="Gobbler" className='gobbler-img'/>
                    <Fade>
                      <span className="button-text">Video Finder</span>
                    </Fade>
                </button>
                <button
                    className={getButtonClass('chatbot-tool')}
                    onClick={() => setSelectedComponent('chatbot-tool')}
                >
                    <img src={chatboticon} alt="Gobbler" className='gobbler-img'/>
                    <Fade>
                      <span className="button-text">Chat Bot</span>
                    </Fade>
                </button>
            </div>
          </div>
          <div className='navigationv2-bottom-container'>
            <img src={ status === 'Connected' ? statusPulse : statusPulse2 } alt=''></img>
            <p className={ status === 'Connected' ? 'connected' : 'reconnecting' }>{status}</p>
          </div>
        </div>
    );
};

export default NavigationV2;
