import React from 'react';
import Unsubscribe from '../components/Unsubscribe';

const UnsubscribeRoute = () => {

  return (
    <>
      <div>
        <main>
          <Unsubscribe />
        </main>
      </div>
    </>
  );
};

export default UnsubscribeRoute;
