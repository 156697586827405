import React from 'react';
import '../styles/FileDeletion.css';
import { Fade } from 'react-reveal';

const TitleToolError = () => {
  return (
        <div className='max-kb-container'>
            <Fade>
                <label>Please include a video link and topic.</label>
            </Fade>
        </div>
  );
};

export default TitleToolError;
