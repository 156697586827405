import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/Signinform.css';
import Signinform from '../components/Signinform';
import { useAuth } from '../auth/AuthContext';

const Login = () => {
  const { currentUser } = useAuth();

  useEffect(() => {
    // Check if the user is already logged in
    if (currentUser) {
      // Redirect to another route (e.g., home)
      window.location.href = '/'; // Change "/" to your desired route
    }
  }, [currentUser]);

  return (
    <>
      <Helmet>
        <title>ragobble - Log In</title>
        <meta name="description" content="Log in to your ragobble account to access your personalized dashboard and features." />
        <link rel="canonical" href="https://ragobble.com/signin" />
      </Helmet>
      <div>
        <main>
          <section className='background-container'>
            <section className='all-minus-footer'>
            </section>
            <Signinform />
          </section>
        </main>
      </div>
    </>
  );
};

export default Login;
