import React, { useState, useEffect } from 'react';
import '../../styles/TitleTool.css';
import axios from 'axios';
import { useAuth } from '../../auth/AuthContext.js';
import { useWebSocketStatus } from '../WebSocketStatusContext.js';
import * as pdfjsLib from 'pdfjs-dist';
import uploadfile from '../../assets/upload.svg';
import { Tooltip } from '@mui/material';
import wordsOne from '../../assets/100w.svg';
import wordsTwo from '../../assets/200w.svg';
import wordsThree from '../../assets/300w.svg';
import sparkles from '../../assets/sparkles.svg';
import uploadSuccess from '../../assets/uploadLoader.svg';
import PopupImageComponent from '../WbPopup.jsx';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import TitleToolError from '../TitleToolError.jsx';
import uploadLoader from '../../assets/ovalLoader.svg';
import openTitle from '../../assets/uprightarrow.svg';
import copyTitle from '../../assets/copyTitle.svg';
import TitleCopied from '../TitleCopied.jsx';


const TitleTool = () => {
  const { currentUser, authToken } = useAuth();
  const [ws, setWs] = useState(null);
  const [qaPairs, setQaPairs] = useState([]);
  const { setStatus } = useWebSocketStatus();
  const [url, setUrl] = useState('https://www.youtube.com/@');
  const [topic, setTopic] = useState('');
  const [displaySummarizeMessage, setDisplaySummarizeMessage] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTopic, setActiveTopic] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    function connectWebSocket() {
      const token = localStorage.getItem('authToken');
      const websocket = new WebSocket(`wss://ragobble.com/ws?token=${token}`);

      websocket.onopen = () => {
        setStatus('Connected');
        setWs(websocket);
      };

      websocket.onmessage = (event) => {
        try {
          const parsedData = JSON.parse(event.data); // Parse the received JSON data
          setQaPairs(prevQaPairs => {
            // Append new videos to the last answer array
            const lastIdx = prevQaPairs.length - 1;
            if (lastIdx >= 0 && Array.isArray(prevQaPairs[lastIdx].answer)) {
              const updatedAnswer = [
                ...prevQaPairs[lastIdx].answer,
                ...parsedData.answer
              ];
              return [
                ...prevQaPairs.slice(0, lastIdx),
                {
                  ...prevQaPairs[lastIdx],
                  answer: updatedAnswer
                }
              ];
            }
            // Add a new answer object if no previous answer exists
            return [...prevQaPairs, { answer: parsedData.answer }];
          });
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
        // Clear the URL and conversation state after processing the message
        setUrl('');
      };

      websocket.onerror = (error) => {
        console.error('WebSocket Error:', error);
      };

      websocket.onclose = () => {
        setStatus('Reconnecting');
        setTimeout(() => {
          connectWebSocket();
        }, 3000);  // Reconnect after 3 seconds
      };

      return () => {
        websocket.close();
        setStatus('Disconnected');
      };
    }

    const cleanup = connectWebSocket();

    // Cleanup function to close the WebSocket when the component unmounts
    return cleanup;
  }, []);

  const handleUrlChange = (e) => {
    const newUrl = e.target.value;
    setUrl(newUrl);
    setQaPairs([]);
  };

  const resetTitleTool = () => {
    setUrl('');
    setTopic('');
    setQaPairs([]);
};

  const handleTopicChange = (e) => {
    const newTopic = e.target.value;
    setTopic(newTopic);
    setQaPairs([]);
  };

  const submitQuestion = async (e) => {
    e.preventDefault();
    if (!url || !topic) {   
        setTitleError(true);
        setTimeout(() => {
            setTitleError(false);
        }, 3000);
        return;
    }
    setLoading(true);
    setQaPairs([]);
    setDisplaySummarizeMessage(false);
    if (ws && ws.readyState === WebSocket.OPEN) {
      const messagePayload = {
        question: `${topic}`,
        knowledgeBaseContent: url,
        subscriptionStatus: currentUser.subscription.isAdvancedUser ? 'advanced' : 'standard',
        source: 'TitleTool',
      };
      setQaPairs(prevQaPairs => [...prevQaPairs, { question: messagePayload.question, answer: '' }]);
      ws.send(JSON.stringify(messagePayload));
    } else {
      console.error("WebSocket is not connected.");
    }
  };

  return (
    <>
    {titleError && (
      <TitleToolError />
    )}
    {copied && (
      <TitleCopied />
    )}
    <div className='title-tool-container'>
        <div className='title-tool-input-container'>
            <div className='title-ta-container'>
                <textarea
                    value={url}
                    onChange={handleUrlChange}
                    placeholder="Enter YouTube Channel URL"
                />
                <textarea
                    value={topic}
                    onChange={handleTopicChange}
                    placeholder="Enter A Topic"
                />
            </div>
            <button onClick={qaPairs.length > 0 ? resetTitleTool : submitQuestion}
            className={
              qaPairs.length > 0 ? 'title-tool-reset-button' : 'title-tool-submit-button'
            }>
              {qaPairs.length > 0 ? 'Reset' : 'Submit'}
            </button>
        </div>
        <div className='title-tool-answer-container'>
            { qaPairs.length === 1 && (
                <img src={uploadLoader} alt='' className='title-tool-loader'></img>
            )}
            { qaPairs.length === 0 && (
              <div className='title-tool-container-blank-message'>
                <div>
                  <img src={sparkles} alt='sparkles'></img>
                  <ul>
                    <li>Paste a YouTube Channel URL</li>
                    <li>Enter a Topic</li>
                    <li>Get Related Videos</li>
                  </ul>
                </div>
              </div>
            )}
            {qaPairs.map((pair, index) => (
            <div key={index} className='title-tool-answer-inner-container'>
                    {Array.isArray(pair.answer) ? (
                        pair.answer.map((video, videoIndex) => (
                        <div key={videoIndex} className='video-detail'>
                            <img src={video.thumbnailUrl} alt={`Thumbnail for ${video.title}`} className='title-thumbnail'/>
                            <h3>{video.title}</h3>
                            <Tooltip title='Open YouTube Video' placement='top' arrow>
                              <a href={video.url} target='_blank' rel='noreferrer'>
                                <img src={openTitle} alt='' className='title-img-open'></img>
                              </a>
                            </Tooltip>
                            <Tooltip title='Copy Video Link' placement='top' arrow>
                              <button onClick={
                                () => {
                                  navigator.clipboard.writeText(video.url);
                                  setCopied(true);
                                  setTimeout(() => {
                                    setCopied(false);
                                  }, 3000);
                                }
                              }>
                                <img src={copyTitle} alt='' className='title-img-copy'></img>
                              </button>
                            </Tooltip>
                        </div>
                        ))
                    ) : (
                        <></>
                    )}
            </div>
            ))}
        </div>
    </div>
    <PopupImageComponent />
    </>
  );
};

export default TitleTool;
