import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PrivacyPolicy from '../components/PrivacyPolicy';

const PrivacyPolicyRoute = () => {

  return (
    <>
      <Helmet>
        <title>ragobble - Privacy</title>
        <meta name="description" content="Read the Privacy Policy of ragobble to learn how we handle your data and protect your privacy." />
        <link rel="canonical" href="https://ragobble.com/privacy" />
      </Helmet>
      <div>
        <main>
          <PrivacyPolicy />
        </main>
      </div>
    </>
  );
};

export default PrivacyPolicyRoute;
