import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/PrivacyTOS.css';
import rightarrow from '../assets/right-arrow.svg';
import { useNavigate } from 'react-router-dom';

const TermsAndConditions = () => {
  const navigate = useNavigate();
  return (
    <div className="terms-conditions">
      <Helmet>
        <title>ragobble - Terms of Service</title>
        <meta name="description" content="Read our terms and conditions to understand the rules and guidelines for using our services." />
      </Helmet>
      <div className="terms-conditions-inner">
        <div className='terms-conditions-inner-button-container'>
          <button onClick={() => navigate('/')}>
            <img src={rightarrow} alt='Right arrow icon' className='tc-img-arrow' />
            <p>Back</p>
          </button>
        </div>
        <h1>Terms and Conditions</h1>
        <p style={{ textDecoration: 'underline', textUnderlineOffset: '5px' }}>Date updated: 05/15/2024</p>
        <section>
          <h2>1. Introduction</h2>
          <p>By using Ragobble, you confirm your acceptance of, and agree to be bound by, these terms and conditions.</p>
        </section>
        <section>
          <h2>2. Agreement to Terms and Conditions</h2>
          <p>This Agreement takes effect on the date on which you first use the Ragobble application.</p>
        </section>
        <section>
          <h2>3. Subscription and Pricing</h2>
          <p>Ragobble provides users with AI-powered tools and services. Subscriptions are available at a cost ranging between $20 to $40 per month, depending on the chosen plan. By subscribing, you agree to the terms of payment and acknowledge that subscription fees are non-refundable.</p>
        </section>
        <section>
          <h2>4. Usage Rights and Termination</h2>
          <p>Ragobble grants users a non-exclusive, non-transferable license to access and use its services. Ragobble reserves the right to terminate or suspend your access at any time, without notice, if you violate these terms or engage in any activity that may harm Ragobble or its users.</p>
        </section>
        <section>
          <h2>5. Intellectual Property Rights</h2>
          <p>All intellectual property rights in and to the Ragobble software, services, and content provided by Ragobble are owned by Ragobble or its licensors. You agree not to copy, reproduce, modify, reverse engineer, decompile, disassemble, or create derivative works based on the Ragobble software, services, or any part thereof without our prior written consent.</p>
        </section>
        <section>
          <h2>6. Prohibition of Code and Idea Theft</h2>
          <p>You are prohibited from stealing, copying, or using any part of the code, design, features, or ideas of Ragobble for any purpose without express permission. Any unauthorized use or theft of Ragobble’s intellectual property will be pursued to the fullest extent of the law.</p>
        </section>
        <section>
          <h2>7. Refunds</h2>
          <p>Due to the nature of digital products, Ragobble subscriptions cannot be refunded or exchanged once access is granted.</p>
        </section>
        <section>
          <h2>8. Disclaimer of Warranties</h2>
          <p>Ragobble is provided on an "as is" and "as available" basis. We do not warrant that Ragobble will meet your requirements or that its operation will be uninterrupted or error-free. All express and implied warranties or conditions not stated in this Agreement (including, without limitation, loss of profits, loss or corruption of data, business interruption, or loss of contracts) are excluded and expressly disclaimed.</p>
        </section>
        <section>
          <h2>9. Limitation of Liability</h2>
          <p>Ragobble shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use the service; (b) any unauthorized access to or use of our servers and/or any personal information stored therein; (c) any interruption or cessation of transmission to or from our services; (d) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our services by any third party; (e) any errors or omissions in any content or for any loss or damage incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available through the services; and/or (f) the defamatory, offensive, or illegal conduct of any third party. In no event shall Ragobble’s aggregate liability for all claims related to the service exceed the greater of twenty dollars ($20) or the amount paid by you to Ragobble for the past twelve months of the service in question.</p>
        </section>
        <section>
          <h2>10. User Responsibilities</h2>
          <p>You are responsible for your use of the Ragobble services and for any content you provide, including compliance with applicable laws, rules, and regulations. You should only provide content that you are comfortable sharing with others.</p>
        </section>
        <section>
          <h2>11. General Terms and Law</h2>
          <p>This Agreement is governed by the laws of the United States. You acknowledge that no joint venture, partnership, employment, or agency relationship exists between you and Ragobble as a result of your use of these services. You agree not to hold yourself out as a representative, agent, or employee of Ragobble. You agree that Ragobble will not be liable by reason of any representation, act, or omission to act by you.</p>
        </section>
        <section>
          <h2>Contact Us</h2>
          <p>If you have any questions about our Terms of Service, please contact us at: <a href="mailto:admin@ragobble.com">admin@ragobble.com</a></p>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions;
