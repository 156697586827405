import React, { useState, useEffect } from 'react';
import '../styles/ErrorMessageOverlay.css'; // Import your CSS file

const ErrorMessageOverlay = ({ message }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setIsVisible(true);
      const timeout = setTimeout(() => {
        setIsVisible(false);
      }, 3000); // Change this value to control how long the overlay is visible
      return () => {
        clearTimeout(timeout);
        setIsVisible(false); // Reset isVisible to false when the component unmounts
      };
    }
  }, [message]);

  return (
    <div className={`error-message-overlay ${isVisible ? 'active' : ''}`}>
      {message}
    </div>
  );
};

export default ErrorMessageOverlay;
