import React, { useState, useEffect, useRef } from 'react';
import '../../styles/ChatBotTool.css';
import axios from 'axios';
import { useAuth } from '../../auth/AuthContext.js';
import { useWebSocketStatus } from '../WebSocketStatusContext.js';
import * as pdfjsLib from 'pdfjs-dist';
import { Document, Page } from 'react-pdf';
import uploadfile from '../../assets/upload.svg';
import { Tooltip } from '@mui/material';
import PopupImageComponent from '../WbPopup.jsx';
import agreed from '../../assets/agreed2.svg';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Logo from '../../assets/ragobble.svg';
import avatar from '../../assets/avatar-final.svg';
import upArrow from '../../assets/right-arrow.svg';
import ringLoader from '../../assets/ringLoader.svg';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

const ChatBotTool = () => {
  const { currentUser, authToken } = useAuth();
  const [ws, setWs] = useState(null);
  const [qaPairs, setQaPairs] = useState([]);
  const { setStatus } = useWebSocketStatus();
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfText, setPdfText] = useState('');
  const [url, setUrl] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [question, setQuestion] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [screenshot, setScreenshot] = useState('');
  const [isYouTubeUrl, setIsYouTubeUrl] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const containerRef = useRef(null);

  useEffect(() => {
    function connectWebSocket() {
      const token = localStorage.getItem('authToken');
      const websocket = new WebSocket(`wss://ragobble.com/ws?token=${token}`);

      websocket.onopen = () => {
        setStatus('Connected');
        setWs(websocket);
      };

      websocket.onmessage = (event) => {
        setQaPairs(prevQaPairs => {
          const lastIdx = prevQaPairs.length - 1;
          if (lastIdx >= 0) {
            return [
              ...prevQaPairs.slice(0, lastIdx),
              {
                ...prevQaPairs[lastIdx],
                answer: prevQaPairs[lastIdx].answer + event.data
              }
            ];
          }
          return prevQaPairs;
        });
      };

      websocket.onerror = (error) => {
        console.error('WebSocket Error:', error);
      };

      websocket.onclose = () => {
        setStatus('Reconnecting');
        setTimeout(() => {
          connectWebSocket();
        }, 3000);  // Reconnect after 3 seconds
      };

      return () => {
        websocket.close();
        setStatus('Disconnected');
      };
    }

    const cleanup = connectWebSocket();

    // Cleanup function to close the WebSocket when the component unmounts
    return cleanup;
  }, []);

  useEffect(() => {
    const fetchScreenshot = async () => {
      if (url) {
        const isYouTube = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/.test(url);
        if (isYouTube) {
          const videoId = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/)[1];
          setThumbnailUrl(`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`);
          setIsYouTubeUrl(true);
        } else {
          try {
            const response = await axios.post('https://ragobble.com/screenshot', { url });
            setScreenshot(`data:image/png;base64,${response.data}`);
            setIsYouTubeUrl(false);
          } catch (error) {
            console.error('Error fetching screenshot:', error);
            setScreenshot('');
            setIsYouTubeUrl(false);
          }
        }
      } else {
        setScreenshot('');
        setIsYouTubeUrl(false);
      }
    };

    fetchScreenshot();
  }, [url]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    };

    const timeoutId = setTimeout(scrollToBottom, 100); // Adjust the delay as needed

    return () => clearTimeout(timeoutId);
  }, [qaPairs]);

  const handleFileChange = (e) => {
    setQaPairs([]);
    setUrl('');
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      setPdfFile(file);
      convertPdfToText(file);
      setUrl('');  // Clear the URL input if a PDF is uploaded
      setQaPairs([]);
    } else {
      console.error("Please upload a PDF file.");
    }
  };

  const convertPdfToText = (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const typedArray = new Uint8Array(e.target.result);
      const pdf = await pdfjsLib.getDocument(typedArray).promise;
      let text = '';
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        text += textContent.items.map(item => item.str).join(' ');
      }
      setPdfText(text);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleUrlChange = (e) => {
    const newUrl = e.target.value;
    setUrl(newUrl);
    setPdfFile(null);  // Clear the PDF file if a URL is entered
    setPdfText('');
    setQaPairs([]);
  };

  const submitQuestion = async (e) => {
    e.preventDefault();
    if (!url && !pdfFile) {
      console.error("Please upload a PDF file or enter a URL.");
      return; 
    };

    const knowledgeBaseContent = url || pdfText;
    if (ws && ws.readyState === WebSocket.OPEN) {
      setQuestion('');
      setQaPairs(prevQaPairs => [...prevQaPairs, { question: question, answer: '' }]);
      ws.send(JSON.stringify({
        question: question,
        knowledgeBaseContent: knowledgeBaseContent,
        subscriptionStatus: currentUser.subscription.isAdvancedUser ? 'advanced' : 'standard', 
        source: 'ChatBot',
        isUrl: url.length > 0,
      }));
    } else {
      console.error("WebSocket is not connected.");
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  const previousPage = () => {
    if (pageNumber > 1) {
      changePage(-1);
    }
  };

  const nextPage = () => {
    if (pageNumber < numPages) {
      changePage(1);
    }
  };

  return (
    <div className='chatbottool-container'>
      <div className='chatbottool-input-container'>
        <input type='file' id='file' accept='.pdf' onChange={handleFileChange} />
        <Tooltip title='Upload PDF' arrow>
          <label htmlFor='file'>
            <img src={uploadfile} alt='Upload File' />
          </label>
        </Tooltip>
        <small>or</small>
        <input type='text' placeholder='URL' value={url} onChange={handleUrlChange} />
        <div className='chatbot-upload-status-container'>
          <section className='pulse-container2'>
            {url || pdfFile ? (
              <>
                <div className='pin3'></div>
                <div className='pin-effect3'></div>
                <p className='ready-pulse-p'>Ready To Chat</p>
              </>
            ) : (
              <>
                <div className='pin4'></div>
                <div className='pin-effect4'></div>
                <p className='waiting-pulse-p'>Waiting On User</p>
              </>
            )}
          </section>
        </div>
      </div>
      <div className='chatbot-browser-inference-container'>
        <div className='chatbot-browser-container'>
          {pdfFile && (
            <>
              <Document
                file={pdfFile}
                onLoadSuccess={onDocumentLoadSuccess}
                className='pdf-document'
              >
                <Page pageNumber={pageNumber}/>
              </Document>
              <div className="chatbot-pdf-pagination">
                <button onClick={previousPage} disabled={pageNumber <= 1}>Previous</button>
                <span>Page {pageNumber} of {numPages}</span>
                <button onClick={nextPage} disabled={pageNumber >= numPages}>Next</button>
              </div>
            </>
          )}
          {url && !pdfFile && (
            <>
              {isYouTubeUrl ? (
                <img src={thumbnailUrl} alt="YouTube Thumbnail" className='webpage-screenshot' />
              ) : (
                screenshot ? (
                  <img src={screenshot} alt="Screenshot" className='webpage-screenshot' />
                ) : (
                  <>
                    <img src={ringLoader} alt="loader" className='webpage-screenshot-loader' />
                    <p className='webpage-screenshot-message'>Loading Webpage Preview</p>
                  </>
                )
              )}
            </>
          )}
        </div>
        <div className='chatbot-inference-container'>
          <div className='chatbot-inference-inner-container'>
            <div className='chatbot-inference-answer-container'>
            {qaPairs.map((pair, index) => (
              <>
                <div key={index} className='chatbot-inference-question'>
                  <div className='chatbot-answer-image-container'>
                    <div className='gobbler-question-container-image-container'>
                      <img src={avatar} className='ai-message-logo' alt=''></img>
                    </div>
                    <p>{pair.question}</p>
                  </div>
                </div>
                <div key={index} className='chatbot-inference-answer'>
                  <div className='gobbler-answer-container-image-container'>
                    <img src={Logo} className='ai-message-logo' alt=''></img>
                  </div>
                  <ReactMarkdown
                    children={pair.answer}
                    remarkPlugins={[remarkGfm]}
                    components={{
                      ul: ({ node, ...props }) => (
                        <ul style={{ listStyleType: 'disc', fontFamily: 'Inter Light', margin: '10px 0', paddingLeft: '20px' }} {...props} />
                      ),
                      li: ({ node, ...props }) => (
                        <li style={{ fontFamily: 'Inter Light', margin: '5px 0' }} {...props} />
                      ),
                      p: ({ node, ...props }) => (
                        <p style={{ fontFamily: 'Inter Light', margin: '10px 0', lineHeight: '1.6' }} {...props} />
                      ),
                      ol: ({ node, ...props }) => (
                        <ol style={{ listStyleType: 'decimal', fontFamily: 'Inter Light', margin: '10px 0', paddingLeft: '20px' }} {...props} />
                      ),
                      h3: ({ node, ...props }) => (
                        <h3 style={{ fontFamily: 'Inter Light', fontWeight: 'bold', fontSize: '1.25em', margin: '15px 0' }} {...props} />
                      ),
                      h4: ({ node, ...props }) => (
                        <h4 style={{ fontFamily: 'Inter Light', fontWeight: 'bold', fontSize: '1.1em', margin: '15px 0' }} {...props} />
                      ),
                      blockquote: ({ node, ...props }) => (
                        <blockquote style={{ fontFamily: 'Inter Light', fontStyle: 'italic', margin: '10px 0', paddingLeft: '15px', borderLeft: '3px solid #ccc', color: '#666' }} {...props} />
                      ),
                    }}
                  />
                </div>
              </>
            ))}
            </div>
          </div>
          <div className='chatbot-inference-question-asker-container'>
          <Tooltip title={!url && !pdfFile ? 'Please Upload a File or URL' : ""} arrow placement='top'>
            <div className='chatbot-inference-question-asker-inner'>
                <textarea
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  placeholder="Ask a question..."
                  rows={1}
                  className='chatbot-inference-textarea'
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && question) {
                      e.preventDefault();
                      submitQuestion(e);
                    }
                  }}
                  disabled={!url && !pdfFile}
                />
                <Tooltip title={url || pdfFile ? "Send message" : ""} arrow>
                <button onClick={submitQuestion} disabled={!question}>
                  <img src={upArrow} alt="" className='chatbot-inference-send-button-img' />
                </button>
                </Tooltip>
            </div>
          </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBotTool;


// Swap YouTubeLoader for custom function.
