import React from 'react';
import '../styles/FileDeletion.css';
import { Fade } from 'react-reveal';

const MaxKb = () => {
  return (
        <div className='max-kb-container'>
            <Fade>
                <label>Please upgrade your plan to<br></br>add more Knowledge-Bases</label>
            </Fade>
        </div>
  );
};

export default MaxKb;
