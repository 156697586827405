import React from 'react';
import '../styles/FileDeletion.css';
import { Fade } from 'react-reveal';

const DuplicateKb = () => {
  return (
        <div className='duplicate-folder-container'>
            <Fade>
                <label>Knowledge-Base Already Exists!</label>
            </Fade>
        </div>
  );
};

export default DuplicateKb;
