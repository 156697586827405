import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/Bottombanner.css';
import { Link } from 'react-router-dom';
import { Fade } from 'react-reveal';
import rightarrowblue from '../assets/right-arrow-white.svg';

const Bottombanner = () => {
  return (
    <div className='bottom-banner-container'>
      <Helmet>
        <meta name="description" content="Join Ragobble today for free and explore our AI-driven tools. Sign up now to create knowledge bases, upload files, and ask questions effortlessly." />
        <meta name="keywords" content="Ragobble, free signup, AI tools, knowledge base, upload files" />
        <meta name="author" content="Ragobble" />
        <meta property="og:title" content="ragobble - Start Today For Free" />
        <meta property="og:description" content="Join Ragobble today for free and explore our AI-driven tools. Sign up now to create knowledge bases, upload files, and ask questions effortlessly." />
        <meta property="og:image" content="/path/to/start-today-image.jpg" />
        <meta property="og:url" content="https://www.ragobble.com/signup" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ragobble - Start Today For Free" />
        <meta name="twitter:description" content="Join Ragobble today for free and explore our AI-driven tools. Sign up now to create knowledge bases, upload files, and ask questions effortlessly." />
        <meta name="twitter:image" content="/path/to/start-today-image.jpg" />
      </Helmet>
      <Fade cascade delay={750}>
        <h1>Start Today For Free!</h1>
        <Link to='/signup' className='get-started-button' aria-label="Get started with Ragobble">
          Get Started
          <img src={rightarrowblue} alt="Right arrow icon" className='right-arrow' />
        </Link>
      </Fade>
    </div>
  )
}

export default Bottombanner;
