import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/PrivacyTOS.css';
import rightarrow from '../assets/right-arrow.svg';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <div className="privacy-policy">
      <Helmet>
        <meta name="description" content="Read our privacy policy to understand how we handle your personal information and data." />
      </Helmet>
      <div className="privacy-policy-inner">
        <div className="privacy-policy-inner-button-container">
          <button onClick={() => navigate('/')}>
            <img src={rightarrow} alt='Right arrow icon' className='pp-img-arrow' />
            <p>Back</p>
          </button>
        </div>
        <h1>Privacy Policy</h1>
        <p style={{ textDecoration: 'underline', textUnderlineOffset: '5px' }}>Date updated: 05/15/2024</p>
        <section>
          <p>Welcome to our Privacy Policy. Your privacy is critically important to us. This policy explains how we handle your personal information and data.</p>
        </section>
        <section>
          <h2>Information We Collect</h2>
          <p>We collect information that you provide directly to us when you use our application. This includes information such as account details like your name, email address, and password, as well as data uploaded or created by you within the application.</p>
        </section>
        <section>
          <h2>How We Use Your Information</h2>
          <p>We use your information to provide and maintain our services, communicate with you about your account and our services, and improve our services and develop new features.</p>
        </section>
        <section>
          <h2>Protection of Your Information</h2>
          <p>We take reasonable measures to protect your information from unauthorized access, use, or disclosure.</p>
        </section>
        <section>
          <h2>Data Deletion</h2>
          <p>When you delete data in our application, it is permanently removed from our systems and cannot be recovered. We ensure that your deleted data is completely purged from our systems.</p>
        </section>
        <section>
          <h2>Information Sharing</h2>
          <p>We do not sell, trade, or otherwise transfer your personal information to third parties. We may share your information with trusted service providers who assist us in operating our application, provided that they agree to keep this information confidential.</p>
        </section>
        <section>
          <h2>Consent</h2>
          <p>By using our application, you consent to our Privacy Policy.</p>
        </section>
        <section>
          <h2>Policy Updates</h2>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        </section>
        <section>
          <h2>Contact Us</h2>
          <p>If you have any questions about our Privacy Policy, please contact us at: <a href="mailto:admin@ragobble.com">admin@ragobble.com</a></p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
